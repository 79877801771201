import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import WoInfotipData from "./WoInfotipData";
import WoWpData from "./WoWpData";
const XLSX = require('xlsx');

function DashboardTable({ refreshData }) {
  const [woList, setWoList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageArray, setPageArray] = useState([1, 2, 3, 4, 5]);
  const [cache, setCache] = useState({});
  const [infotipData, setInfotipData] = useState([]);
  const [infotipListSaved, setInfotipListSaved] = useState([]);
  const [wpData, setWpData] = useState([]);
  const [shouldFilter, setShouldFilter] = useState(false);

  const [infotipWoNumber, setInfotipWoNumber] = useState("");
  const [asp, setAsp] = useState("");
  const [status, setStatus] = useState("");
  const [orderType, setOrderType] = useState("");
  const [workshop, setWorkshop] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [creatorRole, setCreatorRole] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [productName, setProductName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [aspOptions, setAspOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [workshopOptions, setWorkshopOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [creatorRoleOptions, setCreatorRoleOptions] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingWp, setLoadingWp] = useState(true);

  const itemsPerPage = 10;
  const usertype = localStorage.getItem('usertype')
  const workshopname = localStorage.getItem('workshop')
  const asc = localStorage.getItem('ASC')
  console.log(usertype,workshopname,asc,"typeee")

  const getPaginatedElements = (page) => {
    if (currentPage === page || page <= 0 || page > totalPages || loading) return;
    setCurrentPage(page)
    if (totalPages <= 5) {
      setPageArray([...Array(totalPages).keys()].map(n => n + 1));
    } else {
      if (page === 1) {
        setPageArray([1, 2, 3, 4, 5]);
      } else if (page < totalPages - 3) {
        setPageArray([page - 1, page, page + 1, page + 2, page + 3]);
      } else {
        setPageArray([totalPages - 5, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1]);
      }
    }
    setLoading(true)
    fetchDetailedInfo(woList, page)
  }

  const fetchDetailedInfo = async (array, page) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const WoNum = array.slice(startIndex, endIndex);

    if (cache[page]) {
      setInfotipListSaved(cache[page]);
      setInfotipData(cache[page]);
      setLoading(false);
    } else {
      try {
        const detailedInfo = await axios.post('https://wprepairacc.tpvai.com/api/getPythonData', { WoNum });
        // console.log(detailedInfo.data)
        const detailedData = Object.values(detailedInfo.data);
        setCache(prevCache => ({ ...prevCache, [page]: detailedData }));
        setInfotipListSaved(detailedData);
        setInfotipData(detailedData);
        setLoading(false);
      } catch (err) {
        console.log("Error getting data: ", err);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // axios
    //   .get("https://wprepairacc.tpvai.com/api/infotips")
    //   .then((result) => {
    //     setInfotipData(result.data);
    //     setAspOptions([...new Set(result.data.map(data => data.ASP))]);
    //     setStatusOptions([...new Set(result.data.map(data => data.Infotip_Status))]);
    //     setOrderTypeOptions([...new Set(result.data.map(data => data.Type_of_Order))]);
    //     setWorkshopOptions([...new Set(result.data.map(data => data.Workshop))]);
    //     setCountryOptions([...new Set(result.data.map(data => data.Country))]);
    //     setCreatorRoleOptions([...new Set(result.data.map(data => data.Creator_Role))]);
    //   })
    //   .catch((err) => {
    //     console.log("Error getting data infotip: ", err);
    //   });

    setOrderTypeOptions(["guarantee", "doa", "repair"])
    const fetchData = async () => {
      try {
        const [countryResult, workshopResult, infotipListResult] = await Promise.all([
          axios.get("https://wprepairacc.tpvai.com/api/countries"),
          axios.get("https://wprepairacc.tpvai.com/api/workshops"),
          axios.get("https://wprepairacc.tpvai.com/api/infotipList")
        ]);

        setCountryOptions([...new Set(countryResult.data.map(data => data.country_code))]);
        setWorkshopOptions([...new Set(workshopResult.data.map(data => data.workshopname))]);

        const listWO = infotipListResult.data.split(',');
        setWoList(listWO);
        const calculatedTotalPages = Math.ceil(listWO.length / itemsPerPage);
        setTotalPages(calculatedTotalPages);
        calculatedTotalPages <= 5 && setPageArray([...Array(calculatedTotalPages).keys()].map(n => n + 1));

        await fetchDetailedInfo(listWO, 1);
      } catch (err) {
        console.log("Error getting data: ", err);
        setLoading(false);
      }
    };

    fetchData();

    // axios
    //   .get("https://wprepairacc.tpvai.com/api/infotipList")
    //   .then(async (result) => {
    //     const lines = String(result.data).split('\n');
    //     lines.pop();
    //     lines.pop();
    //     const header = String(lines.shift().split('|')[4]).split(',');

    //     // Function to fetch detailed info for a given order number
    //     const fetchDetailedInfo = async (WoNum) => {
    //       try {
    //         const response = await axios.post('https://wprepairacc.tpvai.com/api/getPythonData', { WoNum });
    //         const detailedInfo = response.data[WoNum];
    //         const keysToMerge = ['ModelName', 'SerialNumber', 'CountryId', 'WorkshopName', 'CreatorRole', 'TypeOfOrder'];
    //         const toMergeInfo = {};

    //         keysToMerge.forEach(key => {
    //           if (detailedInfo.hasOwnProperty(key)) {
    //             toMergeInfo[key] = detailedInfo[key];
    //           }
    //         });

    //         return toMergeInfo;
    //       } catch (err) {
    //         console.log("Error getting data: ", err);
    //         return {};
    //       }
    //     };

    //     // Collect all promises for fetching detailed info
    //     const promises = lines.map(async (line) => {
    //       const values = line.split('|');
    //       const obj = {};
    //       header.forEach((key, index) => {
    //         obj[key] = values[index];
    //       });

    //       if (obj["OrderName"]) {
    //         const detailedInfo = await fetchDetailedInfo(obj["OrderName"]);
    //         return { ...obj, ...detailedInfo };
    //       } else {
    //         return obj;
    //       }
    //     });

    //     // Wait for all promises to resolve
    //     try {
    //       const infotipList = await Promise.all(promises);
    //       setInfotipListSaved(infotipList);
    //       setInfotipData(infotipList);
    //       setLoading(false);
    //     } catch (err) {
    //       console.log("Error processing infotip data: ", err);
    //       setLoading(false);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("Error getting infotip list: ", err);
    //     setLoading(false);
    //   });


    // axios
    //   .get("https://wprepairacc.tpvai.com/api/infotipList")
    //   .then(async (result) => {
    //     const detailedInfo = result.data

    //     try {
    //       console.log((detailedInfo))
    //       // setInfotipListSaved(Object.values(detailedInfo));
    //       // setInfotipData(Object.values(detailedInfo));
    //       setLoading(false);
    //     } catch (err) {
    //       console.log("Error processing infotip data: ", err);
    //       setLoading(false);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("Error getting infotip list: ", err);
    //     setLoading(false);
    //   });

  }, []);


  useEffect(() => {
    const fetchData = async () => {
      setLoadingWp(true);
      axios
        .get("https://wprepairacc.tpvai.com/api/wps")
        .then((result) => {
          setWpData(result.data);
          setLoadingWp(false);
        })
        .catch((err) => {
          console.log("Error getting data wp: ", err);
        });
    };

    fetchData();
  }, [refreshData]);


  const handleFilter = useCallback(() => {
    // const filters = {
    //   Infotip_WO_Number: infotipWoNumber,
    //   ASP: asp,
    //   Infotip_Status: status,
    //   Type_of_Order: orderType,
    //   Workshop: workshop,
    //   Country: country,
    //   Creator_Role: creatorRole,
    //   Serial_Number: serialNumber,
    //   Product_Name: productName,
    //   Start_Date: startDate,
    //   End_Date: endDate
    // };

    // axios
    //   .post("https://wprepairacc.tpvai.com/api/filterInfo", filters)
    //   .then((result) => {
    //     // setInfotipData(result.data);
    //   })
    //   .catch((error) => {
    //     console.error("Error filtering: ", error);
    //   });

    const filters = {
      OrderName: infotipWoNumber,
      // ASP: asp,
      OrderState: status,
      TypeOfOrder: orderType,
      WorkshopName: workshop,
      CountryId: country,
      CreatorRole: creatorRole,
      SerialNumber: serialNumber,
      ProductName: productName,
      Phone: phone,
      Start_Date: startDate,
      End_Date: endDate
    };

    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split('.');
      return new Date(`${year}-${month}-${day}`);
    };

    const start = filters.Start_Date ? parseDate(filters.Start_Date) : null;
    const end = filters.End_Date ? parseDate(filters.End_Date) : null;

    let filteredData = [];

    // Iterate over each object in the data array and apply filters
    infotipListSaved.forEach(item => {
      let passesFilters = true;

      // Check if each property in filters matches the corresponding property in the item
      Object.keys(filters).forEach(key => {
        if (key === 'Start_Date' || key === 'End_Date') {
          return;
        }

        if (filters[key] && item[key] !== filters[key]) {
          passesFilters = false;
        }
      });

      const itemOrderDate = parseDate(item.OrderDate.split(' ')[0]);

      // Check if itemOrderDate is within the range
      if (start && itemOrderDate < start) {
        passesFilters = false;
      }
      if (end && itemOrderDate > end) {
        passesFilters = false;
      }

      // If the item passes all filters, add it to the filteredData array
      if (passesFilters) {
        filteredData.push(item);
      }
    });
    setInfotipData(filteredData)
  }, [status, orderType, workshop, country, creatorRole, infotipWoNumber, serialNumber, productName, phone, startDate, endDate, infotipListSaved]);


  useEffect(() => {
    if (shouldFilter) {
      handleFilter();
      setShouldFilter(false); // Reset shouldFilter after filtering
    }
  }, [shouldFilter, handleFilter]);


  const handleClear = () => {
    setInfotipWoNumber("");
    setAsp("");
    setStatus("");
    setOrderType("");
    setWorkshop("");
    setCountry("");
    setPhone("");
    setCreatorRole("");
    setSerialNumber("");
    setProductName("");
    setStartDate("");
    setEndDate("");

    setEndIndex(10);

    setShouldFilter(true);
  };

  const [endIndex, setEndIndex] = useState(10);
  const handleEndIndexChange = (e) => {
    setEndIndex(parseInt(e.target.value));
  };

  const handleExport1 = () => {
    // var fileName = window.prompt("Please enter the file name (without extension):");
    // if (!fileName) return;
    // const excelData = infotipData.map(({ _id, ...rest }) => rest);
    const excelData = infotipData.map(({ OrderDate, OrderName, HandlingType, ProductName, SerialNumber, CountryId, WorkshopName, OrderState, CreatorRole, TypeOfOrder }) =>
      ({ OrderDate, OrderName, HandlingType, ProductName, SerialNumber, CountryId, WorkshopName, OrderState, CreatorRole, TypeOfOrder }));
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(excelData);

    XLSX.utils.book_append_sheet(wb, ws, "Infotip WO");
    // XLSX.writeFile(wb, fileName + ".xlsx");
    // XLSX.writeFile(wb, "repair system.xlsx");
    // ---------
    const excelData2 = wpData.map(({ Order_Date_Time, WP_WO_Number, Handling_Type, Product_Name, Serial_Number, Country, Workshop, ASP, Infotip_Status, Creator_Role, Type_of_Order, RR }) =>
      ({ Order_Date_Time, WP_WO_Number, Handling_Type, Product_Name, Serial_Number, Country, Workshop, ASP, Infotip_Status, Creator_Role, Type_of_Order, RR }));
    // var wb2 = XLSX.utils.book_new(),
    var ws2 = XLSX.utils.json_to_sheet(excelData2);

    XLSX.utils.book_append_sheet(wb, ws2, "Woodpecker WO");
    XLSX.writeFile(wb, 'repair system.xlsx');
  }

  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    console.log(asp,"asppp")
    const filteredInfotipData = (usertype === 'ASC Leader' || usertype === 'ASC Technician' || usertype === 'TPV Technician') 
    ? infotipData.filter(item => item.ASP === asc)
    : infotipData;
  
      const excelData = filteredInfotipData.map(({ OrderDate, OrderName, HandlingType, ProductName, SerialNumber, CountryId, WorkshopName, OrderState, CreatorRole, TypeOfOrder }) =>
        ({ OrderDate, OrderName, HandlingType, ProductName, SerialNumber, CountryId, WorkshopName, OrderState, CreatorRole, TypeOfOrder }));
      
      const ws = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, ws, "Infotip WO");

      // const filteredWpData = wpData.filter(item => item.ASP === asp);
      const filteredWpData = (usertype === 'ASC Leader' || usertype === 'ASC Technician' || usertype === 'TPV Technician') 
    ? wpData.filter(item => item.ASP === asc)
    : wpData;
  
      const excelData2 = filteredWpData.map(({ Order_Date_Time, WP_WO_Number, Handling_Type, Product_Name, Serial_Number, Country, Workshop, ASP, Infotip_Status, Creator_Role, Type_of_Order, RR }) =>
        ({ Order_Date_Time, WP_WO_Number, Handling_Type, Product_Name, Serial_Number, Country, Workshop, ASP, Infotip_Status, Creator_Role, Type_of_Order, RR }));
      
      const ws2 = XLSX.utils.json_to_sheet(excelData2);
      XLSX.utils.book_append_sheet(wb, ws2, "Woodpecker WO");

    // Save the file
    XLSX.writeFile(wb, 'repair_system_export.xlsx');
  }
  

  return (
    <div>
      {/* {console.log(infotipData)} */}
      <div className="dashboard-container">
        <h3>Workorder Dashboard</h3>
        <div className="dashboard-table-container">
          <div className="table-top">
            <div className="table-item">
              <label>Infotip WO no.: </label>
              <input type="text" value={infotipWoNumber} onChange={(e) => setInfotipWoNumber(e.target.value)}></input>
            </div>
            { (usertype === 'TPV Leader' || usertype === 'Manager') &&(
            <div className="table-item">
              <label>ASC: </label>
              <select value={asp} onChange={(e) => setAsp(e.target.value)}>
                <option value="">Select ASC</option>
                {aspOptions.map((data, i) => (
                  <option key={i} value={data}>
                    {data}
                  </option>
                ))}
              </select>
            </div>
            )}

            {usertype === 'TPV Technician' && (
              <div className="table-item">
                <label>ASC: </label>
                <select value={asp} onChange={(e) => setAsp(e.target.value)}>
                  {/* <option value="">Select ASC</option> */}
                  <option value="" selected>None</option>
                </select>
              </div>
            )}


            {(usertype === 'ASC Leader'||usertype === 'ASC Technician') && (
              <div className="table-item">
                <label>ASC: </label>
                <select value={asp} onChange={(e) => setAsp(e.target.value)}>
                  {/* <option value="">Select ASC</option> */}
                  <option value={asc} selected>{asc}</option>
                </select>
              </div>
            )}

            <div className="table-item">
              <label>Infotip Status: </label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                {statusOptions.map((data, i) => (
                  <option key={i} value={data}>
                    {data}
                  </option>
                ))}
              </select>
            </div>
            <div className="table-item large">
              <label>Woodpecker Status: </label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                {statusOptions.map((data, i) => (
                  <option key={i} value={data}>
                    {data}
                  </option>
                ))}
              </select>
            </div>
           
            {(usertype === 'TPV Leader' || usertype === 'Manager' || usertype === 'ASC Leader' || usertype === 'ASC Technician'||usertype === 'Manager') && (
              <div className="table-item">
                <label>Workshop: </label>
                <select
                  value={workshop}
                  onChange={(e) => setWorkshop(e.target.value)} >
                  <option value="">Select Workshop</option>
                  {workshopOptions.map((data, i) => (
                    <option key={i} value={data}>
                      {data}
                    </option>
                  ))}
                </select>
              </div>
            )}
             
            {usertype === 'TPV Technician' && (
              <div className="table-item">
                <label>Workshop: </label>
                <select
                  value={workshop}
                  onChange={(e) => setWorkshop(e.target.value)} >
                  <option value="">Select Workshop</option>
                  <option value={workshopname}>{workshopname}</option>
                </select>
              </div>
            )}

            <div className="table-item">
              <label>Serial Number: </label>
              <input type="text" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)}></input>
            </div>
            <div className="table-item">
              <label>Country: </label>
              <select
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="">Select Country</option>
                {countryOptions.map((data, i) => (
                  <option key={i} value={data}>
                    {data}
                  </option>
                ))}
              </select>
            </div>
            <div className="table-item large">
              <label>Creator Role: </label>
              <select
                value={creatorRole}
                onChange={(e) => setCreatorRole(e.target.value)}
              >
                <option value="">Select CreatorRole</option>
                {creatorRoleOptions.map((data, i) => (
                  <option key={i} value={data}>
                    {data}
                  </option>
                ))}
              </select>
            </div>
            <div className="table-item">
              <label>Type of Order: </label>
              <select
                value={orderType}
                onChange={(e) => setOrderType(e.target.value)}
              >
                <option value="">Select Ordertype</option>
                {orderTypeOptions.map((data, i) => (
                  <option key={i} value={data}>
                    {data}
                  </option>
                ))}
              </select>
            </div>
            <div className="table-item">
              <label>Product Name: </label>
              <input type="text" value={productName} onChange={(e) => setProductName(e.target.value)}></input>
            </div>
            <div className="table-item">
              <label>Customer Phone: </label>
              <input type="number" className="no-spinners" value={phone} onChange={(e) => setPhone(e.target.value)}></input>
            </div>
            <div className="table-item large">
              <label>Order Date: From </label>
              <input type="text" value={startDate} onChange={(e) => setStartDate(e.target.value)}></input>
              <label>To </label>
              <input type="text" value={endDate} onChange={(e) => setEndDate(e.target.value)}></input>
            </div>
          </div>
          <div className="table-bottom">
            <div>
              <span>Show</span>
              <select className="select-entries" value={endIndex} onChange={handleEndIndexChange}>
                {infotipData.map((_, index) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
              <span>entries</span>
            </div>
            <div className="table-bottom-right">
              <div direction="row" spacing={4} align="center">
                <button className="dashboard-button filled-button" onClick={handleFilter}>
                  Search
                </button>
                <button className="dashboard-button clear-button" onClick={handleClear}>
                  Clear
                </button>
                <button className="dashboard-button filled-button" onClick={handleExport}>Export</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WoInfotipData infotipData={infotipData} endIndex={endIndex} loading={loading} currentPage={currentPage} itemsPerPage={itemsPerPage} />

      {totalPages > 0 &&
        <div style={{ display: "grid" }}>
          <div className="pagination-buttons">
            <button type="button" onClick={() => getPaginatedElements(1)}>First</button>
            <button type="button" onClick={() => getPaginatedElements(currentPage - 1)}>Previous</button>
            {woList.length > 0 && pageArray.map((x, i) => (
              <button className={currentPage === x ? 'current-page-button' : ""} key={i} type="button" onClick={() => getPaginatedElements(x)}>{x}</button>
            ))}
            {totalPages > 6 && <span>...</span>}
            {totalPages > 5 && <button className={currentPage === totalPages ? 'current-page-button' : ""} type="button" onClick={() => getPaginatedElements(totalPages)}>{totalPages}</button>}
            <button type="button" onClick={() => getPaginatedElements(currentPage + 1)}>Next</button>
            <button type="button" onClick={() => getPaginatedElements(totalPages)}>Last</button>
          </div>
        </div>}

      <WoWpData wpData={wpData} loading={loadingWp} />
    </div >
  );
}

export default DashboardTable;
