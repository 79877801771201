import React, { useRef, useState } from 'react'
import axios from 'axios'
import { Box, CircularProgress, Backdrop } from '@mui/material';
import ConfirmationModal from './ConfirmationModal';

function Attachment({ textOptions, detailedData, getPythonData }) {
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [attachLoading, setAttachLoading] = useState(false)
  const fileInputRef = useRef(null);

  const handleAddFile = (event) => {
    const fileTypes = [".txt", ".pdf", ".xlsx", ".doc", ".docx", ".ppt", ".pptx", ".zip", ".mp4", "mov"]
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSize = file.size / (1024 * 1024)
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!fileTypes.includes("." + fileExtension)) {
        window.alert(`${fileExtension} file is not allowed!`)
        event.target.value = '';
        return
      }

      if (fileSize > 20) {
        window.alert("File is larger than 20MB")
        event.target.value = '';
        return;
      }

      const newFiles = [...files];

      // let assignAttachmentType = '';
      // switch (fileExtension) {
      //   case 'txt':
      //     assignAttachmentType = 'text';
      //     break;
      //   case 'pdf':
      //     assignAttachmentType = 'document';
      //     break;
      //   case 'xlsx':
      //   case 'xls':
      //     assignAttachmentType = 'spreadsheet';
      //     break;
      //   case 'docx':
      //   case 'doc':
      //     assignAttachmentType = 'document';
      //     break;
      //   case 'pptx':
      //   case 'ppt':
      //     assignAttachmentType = 'presentation';
      //     break;
      //   case 'zip':
      //     assignAttachmentType = 'archive';
      //     break;
      //   case 'mp4':
      //   case 'mov':
      //     assignAttachmentType = 'video';
      //     break;
      //   default:
      //     assignAttachmentType = 'image';
      //     break;
      // }

      const reader = new FileReader(); // FileReader is instantiated.
      reader.onloadend = () => { // onloadend handler is defined but not yet executed.
        // const base64String = reader.result.split(',')[1];
        newFiles.push({
          fileName: file.name,
          dateTime: new Date().toLocaleString(),
          fileExt: fileExtension,
          // content: base64String,
        });
        setFiles(newFiles);
        event.target.value = '';
      };
      reader.readAsDataURL(file); // readAsDataURL method starts reading the file.
    } else {
      console.log("No file selected");
    }
  };

  const handleSelectChange = (i, value) => {
    setSelectedValues(prevState => ({
      ...prevState,
      [i]: value
    }));
  };

  const handleUpload = () => {

    if (files.length === 0) {
      alert("Please add a file to upload")
      return
    }
    setIsOpen(true);
  };

  const handleFilesUpload = () => {
    const formData = new FormData();
    files.forEach(fileData => {
      formData.append('files', fileData.file);
    });

    axios.post('https://wprepairacc.tpvai.com/api/upload', formData)
      .then(response => {
        setFiles([]);
      })
      .catch(error => {
        console.error('Upload failed:', error);
      });
    setIsOpen(false)
  };

  const handleCancel = (delFile) => {
    const newPhotos = files.filter(photo => photo.fileName !== delFile);
    setFiles(newPhotos);
  }

  function getUniqueIndex(arr) {
    const numberArray = arr.map(str => parseInt(str, 10));
    // const numberArray = arr.map(Number);
    const maxNumber = Math.max(...numberArray);
    return (maxNumber + 1).toString();
  }

  const handleConfirm = async (fileName, fileExtension, content, textValue) => {
    // console.log(filePath)
    setAttachLoading(true)
    try {
      const orderIssueNr = detailedData.OrderIssueNr
      const orderName = detailedData.OrderName
      const uniqueIndex = (getUniqueIndex(detailedData.docIndexes))
      // const uniqueIndex = getRandomUniqueNumber(500, 899, detailedData.docIndexes);
      const docName = `${orderName}-${uniqueIndex}.${fileExtension}`;

      const response = await axios.post("https://wprepairacc.tpvai.com/api/sendAttachment", { orderIssueNr, uniqueIndex, docName, content, textValue })
      // console.log(response)
      if (response.data.split('|')[0].trim() === "0") {
        setAttachLoading(false)
        window.alert("Attachment sent to Infotip")
        handleCancel(fileName)
        getPythonData(detailedData.OrderName)
      } else {
        setAttachLoading(false)
        window.alert("Error sending the attachment")
      }
    } catch (error) {
      setAttachLoading(false)
      window.alert("Error sending the attachment")
      console.log("error sending the attachment")
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleCancelUpload = () => {
    setFiles([]);
  }

  return (
    <div>
      <h4>Add Photo:</h4>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <h4>Add File</h4>
        <div>
          <input
            type="file"
            id="fileUpload"
            accept=".txt, .pdf, .xlsx, .doc, .docx, .ppt, .pptx, .zip, .mp4, .mov"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleAddFile}
          />
          <button className="curPo confirm" onClick={handleButtonClick}>Choose File</button>
          <button type='submit' className="curPo confirm" onClick={handleUpload}>Upload</button>
          <button type='reset' className="curPo cancel" onClick={handleCancelUpload}>Cancel</button>
          <ConfirmationModal
            isOpen={isOpen}
            message="Are you sure you want to upload the files?"
            onConfirm={handleFilesUpload}
            onCancel={handleCancel}
          />
        </div>
      </div>
      {files.length !== 0 && (
        <div style={{ display: "flex", alignItems: 'baseline' }}>
          <h4>Files</h4>
          <table style={{ borderSpacing: 20 }}>
            <thead className='files-table'>
              <tr>
                <th>File Name</th>
                <th>Date Time</th>
                <th>Assign Infotip Attachment Type</th>
                <th>Send to Infotip</th>
              </tr>
            </thead>
            <tbody className='files-table' key={File.fileName}>
              {files.map((file, i) => {
                const textValue = selectedValues[i] || '';
                return (
                  <tr>
                    <td>{file.fileName}</td>
                    <td>{file.dateTime}</td>
                    <td>
                      <select className="select-styled" style={{ marginLeft: "-5px" }} required value={textValue} onChange={(e) => handleSelectChange(i, e.target.value)}>
                        <option value="">Select</option>
                        {textOptions && Object.keys(textOptions).length > 0 && Object.entries(textOptions).map(([key, value]) => (
                          <option key={key} value={value}>{key}</option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <button className="confirm curPo" type='button' onClick={() => { handleConfirm(file.fileName, file.fileExt, file.content, textValue) }}>Confirm</button>
                      <button className="curPo cancel" onClick={() => handleCancel(file.fileName)} type="button">Cancel</button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
      {attachLoading &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <Box
            sx={{
              display: 'flex',
              height: '70vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress sx={{ color: 'grey.500' }} />
          </Box>
        </Backdrop>}
      <h4>Send photo link to customer</h4>
    </div>
  )
}

export default Attachment
