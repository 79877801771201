import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import WoInfo from "./WoInfo";
import axios from "axios";
import SingleData from './SingleData';
import HomePick from "./HomePick";
import Diagnosis from "./Diagnosis";
import SpOrder from "./SpOrder";
import Repair from "./Repair";
import Delivery from "./Delivery";
import TcaClaim from "./TcaClaim";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function DetailedInfo({ WoNum }) {
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [woInfoLoading, setWoInfoLoading] = useState(false);
  const [textOptions, setTextOptions] = useState(null)
  let [detailedData, setDetailedData] = useState({});

  function getPythonData(WoNum) {
    setWoInfoLoading(true)
    axios
      .post('https://wprepairacc.tpvai.com/api/getPythonData', { WoNum })
      .then((response) => {
        setDetailedData(response.data);
      }).catch((err) => {
        console.log("Error getting data: ", err)
      })
      .finally(() => {
        setLoading(false);
        setWoInfoLoading(false);
      });
  }

  useEffect(() => {
    const orderType = WoNum.substring(0, 2);

    if (orderType === 'WP') {
      axios
        .post("https://wprepairacc.tpvai.com/api/detailInfoWP", { WoNum })
        .then((response) => {
          setDetailedData(response.data);
        })
        .catch((err) => {
          console.log("Error getting data: ", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // axios
      // .post("https://wprepairacc.tpvai.com/api/detailInfoIT", { WoNum })
      // .then((response) => {
      //   setDetailedData(response.data);
      // })
      // .catch((err) => {
      //   console.log("Error getting data: ", err);
      // });
      getPythonData(WoNum)

    }
  }, [WoNum]);

  useEffect(() => {
    const fetchText = async () => {
      try {
        const textResponse = await axios.get("https://wprepairacc.tpvai.com/api/getText")
        const data = textResponse.data.replace(/'/g, '"');
        const texts = JSON.parse(data)
        // console.log(texts)
        setTextOptions(texts)
      } catch (error) {
        console.error('There was an error getting the texts!', error);
      }
    }
    fetchText();
  }, [])

  if (WoNum.substring(0, 2) === 'IT') {
    // const firstKey = Object.keys(detailedData)[0];
    // detailedData = detailedData[firstKey]
    detailedData = Object.values(detailedData)[0]
    // console.log(detailedData)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {detailedData && detailedData.OrderName && <SingleData infotipData={detailedData} />}
      {loading ? (
        <Box sx={{ display: 'flex', height: "70vh", justifyContent: "center", padding: "5%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* {detailedData && detailedData.OrderName && <SingleData infotipData={detailedData} />} */}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="tabs">
              <Tab label="WO Info" {...a11yProps(0)} />
              <Tab label="Home visit / Pick up" {...a11yProps(1)} />
              <Tab label="Diagnosis" {...a11yProps(2)} />
              <Tab label="SP Order" {...a11yProps(3)} />
              <Tab label="Repair" {...a11yProps(4)} />
              <Tab label="Delivery" {...a11yProps(5)} />
              <Tab label="TCA Claim" {...a11yProps(6)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {woInfoLoading ? (
              <Box sx={{ display: 'flex', height: "70vh", justifyContent: "center", padding: "5%" }}>
                <CircularProgress />
              </Box>
            ) : (
              detailedData && detailedData.OrderName ? (
                <WoInfo detailedInfo={detailedData} setDetailedData={setDetailedData} />
              ) : (
                <span style={{ color: "red", display: "flex", justifyContent: "center" }}>No Data for this WorkOrder</span>
              )
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {detailedData && detailedData.OrderName ? <HomePick detailedData={detailedData} textOptions={textOptions} getPythonData={getPythonData} setLoading={setLoading} />
              : <span style={{ color: "red", display: "flex", justifyContent: "center" }}>No Data for this WorkOrder</span>}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Diagnosis detailedData={detailedData} getPythonData={getPythonData} textOptions={textOptions}/>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <SpOrder />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <Repair />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <Delivery detailedData={detailedData} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={6}>
            <TcaClaim />
          </CustomTabPanel>
        </>
      )}
    </Box>
  );
}

export default DetailedInfo;
