import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

function logout() {
  localStorage.clear();
}

export function Header() {
  const usertype = localStorage.getItem('usertype')
  const workshopname = localStorage.getItem('workshop')
  const asc = localStorage.getItem('ASC')
  console.log(usertype,workshopname,asc,"typeeeinlogin")

  return (
    <>
      <Navbar bg="primary" variant="dark">
        <Container fluid>
          <Nav className="me-auto">
            {<Nav.Link as={Link} to="/dashboard">Repair System</Nav.Link>}
          </Nav>
          <Nav className="ml-auto">
            {usertype && (
              <Nav.Item>
                <Nav.Link as="span">{usertype}</Nav.Link>
              </Nav.Item>
            )}
            <Nav.Link as={Link} to="/login" onClick={logout}>Logout</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}
