import React, { useState } from 'react'
import RepairHistory from './RepairHistory';
import Attachment from './Attachment';
import VideoCall from './VideoCall';
import BuddyTool from './BuddyTool';
import ConfirmationModal from './ConfirmationModal';

function Diagnosis({detailedData, textOptions, getPythonData}) {
    const [isOpen, setIsOpen] = useState(false);
    // const [confirmDiagnose, setConfirmDiagnose] = useState('NO');
    const [diagnoseEditable, setDiagnoseEditable] = useState(false);
    const [toggleRepairHistory, setToggleRepairHistory] = useState(false);
    const [toggleAttachment, setToggleAttachment] = useState(false);
    const [toggleVideoCall, setToggleVideoCall] = useState(false);
    const [toggleBuddyTool, setToggleBuddyTool] = useState(false);

    const [formData, setFormData] = useState({
        mandatorySCC: '',
        ipmApplied: '',
        symptomCode: '',
        sectionCode: '',
        defectCode: '',
        repairCode: '',
        condCode: '',
        comment: '',
    });

    const handleForm = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleChange = (e) => {
        if (!diagnoseEditable) {
            setIsOpen(true);
        } else {
            setDiagnoseEditable(false)
        }
    }

    const handleConfirm = () => {
        setIsOpen(false)
        setDiagnoseEditable(true)
    };

    const handleCancel = (e) => {
        setIsOpen(false);
    };

    // const handleConfirm = (e) => {
    //     e.preventDefault()
    //     confirmDiagnose === 'YES' && setDiagnoseEditable(true)
    // }

    // const handleCancel = () => {
    //     setDiagnoseEditable(false)
    //     setConfirmDiagnose('NO')
    // }

    const handleDiagnoseSubmit = (e) => {
        e.preventDefault()
    }

    const handleClickRepairHistory = () => {
        setToggleRepairHistory(!toggleRepairHistory);
    }

    const handleClickAttachment = () => {
        setToggleAttachment(!toggleAttachment);
    }

    const handleClickVideoCall = () => {
        setToggleVideoCall(!toggleVideoCall);
    }
    const handleClickBuddyTool = () => {
        setToggleBuddyTool(!toggleBuddyTool);
    }

    return (
        <div>
            <div>
                <div style={{ display: 'flex', gap: '1%', alignItems: 'center' }}>
                    <h4>Confirm to Diagnose</h4>
                    <input className='checkbox-styled' type='checkbox' checked={diagnoseEditable} onChange={handleChange} />
                    <ConfirmationModal
                        isOpen={isOpen}
                        message="Are you sure ?"
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                    />
                    {/* <div>
                        <select
                            value={confirmDiagnose}
                            onChange={handleChange}
                            name="confirmDiagnose"
                        >
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                        </select>
                        <button type='submit' className="curPo confirm" onClick={handleConfirm}>Confirm (131)</button>
                        <button type='reset' className="curPo" onClick={handleCancel}>Cancel</button>
                    </div> */}
                </div>
                {diagnoseEditable &&
                    <div>
                        <ul className="diagnose-list">
                            <li>
                                Current SW Version on Consumer TV:{" "}
                                <span>{detailedData.SoftwareActVersion}</span>
                            </li>
                            <li>
                                SW Version updated on Consumer TV:{" "}
                                <input type='text'></input>
                            </li>
                        </ul>

                        <form onSubmit={handleDiagnoseSubmit}>
                            <br></br>
                            <ul className="repair-list">
                                <li>
                                    Mandatory SCC:{" "}
                                    <select
                                        value={formData.mandatorySCC}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value=""></option>
                                    </select>
                                </li>
                                <li>
                                    SCC or IPM applied:{" "}
                                    <select
                                        value={formData.ipmApplied}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value=""></option>
                                    </select>
                                </li>
                                <li>
                                    Symptom Code:{" "}
                                    <select
                                        value={formData.symptomCode}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value=""></option>
                                    </select>
                                </li>
                                <li>
                                    Section Code:{" "}
                                    <select
                                        value={formData.sectionCode}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value="">Select sectionCode</option>
                                        <option>AMX</option>
                                        <option>APA</option>
                                        <option>AXN</option>
                                        <option>CBT</option>
                                        <option>CLK</option>
                                        <option>CTR</option>
                                        <option>DDC</option>
                                        <option>DVD</option>
                                        <option>EXC</option>
                                        <option>FMW</option>
                                        <option>INC</option>
                                        <option>INP</option>
                                        <option>LCD</option>
                                        <option>LMP</option>
                                        <option>NIE</option>
                                        <option>REM</option>
                                        <option>SPK</option>
                                        <option>SYS</option>
                                        <option>WIR</option>
                                        <option>231</option>
                                        <option>431</option>
                                        <option>VPN</option>
                                        <option>WLS</option>
                                        <option>AOE</option>
                                        <option>AAX</option>
                                        <option>PCS</option>
                                        <option>SET</option>
                                    </select>
                                </li>
                                <li>
                                    Defect Code:{" "}
                                    <select
                                        value={formData.defectCode}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value=""></option>
                                    </select>
                                </li>
                                <li>
                                    Repair Code:{" "}
                                    <select
                                        value={formData.repairCode}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value=""></option>
                                    </select>
                                </li>
                                <li>
                                    Cond Code:{" "}
                                    <select
                                        value={formData.condCode}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value=""></option>
                                    </select>
                                </li>
                                <li className='comment-input'>
                                    Comment:{" "}
                                    <input type='text' value={formData.comment} onChange={handleForm}></input>
                                </li>
                            </ul>
                        </form>
                    </div>}
            </div>

            <div>
                <div className='toggle-box'>
                    <button onClick={handleClickRepairHistory}>-</button>
                    <h5>Repeat Repair History</h5>
                </div>
                {toggleRepairHistory && <RepairHistory />}
                <div className='toggle-box'>
                    <button onClick={handleClickAttachment}>-</button>
                    <h5>Attachment</h5>
                </div>
                {toggleAttachment && <Attachment detailedData={detailedData} getPythonData={getPythonData} textOptions={textOptions}/>}
                <div className='toggle-box'>
                    <button onClick={handleClickVideoCall}>-</button>
                    <h5>Video Call</h5>
                </div>
                {toggleVideoCall && <VideoCall />}
                <div className='toggle-box'>
                    <button onClick={handleClickBuddyTool}>-</button>
                    <h5>Buddy Tool</h5>
                </div>
                {toggleBuddyTool && <BuddyTool />}
            </div>
        </div>
    )
}

export default Diagnosis
