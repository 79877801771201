import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Delivery({ detailedData }) {
    const [ownTransportToggle, setOwnTransportToggle] = useState(true)
    const [reqWHToggle, setReqWHToggle] = useState(true)
    const [isOpen, setIsOpen] = useState(false);
    const currentDate = new Date();
    const [selectedDate, setSelectedDate] = useState(null);
    const [delivery, setDelivery] = useState(false);
    const [deliveryNSE, setDeliveryNSE] = useState(false);

    const handleDelivery = () => {
        setDelivery(!delivery)
    }

    const handleDeliveryNSE = () => {
        setDeliveryNSE(!deliveryNSE)
    }

    const handleOwnTransport = () => {
        setOwnTransportToggle(false);
    }

    const handleOwnTransportCancel = () => {
        setOwnTransportToggle(true);
    }

    const handleReqWHToggle = () => {
        setReqWHToggle(false);
    }

    const handleReqWHToggleCancel = () => {
        setReqWHToggle(true);
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        // <div>
        //     <div style={{ display: 'flex' }}>
        //         <input style={{ height: '15px', width: '15px' }} type='checkbox' />
        //         <span style={{ marginRight: "25px" }}>Home Repair (no need for delivery)</span>
        //     </div>
        //     <div>
        //         <div style={{ display: 'flex' }}>
        //             <input style={{ height: '15px', width: '15px' }} type='checkbox' onChange={handleDelivery} />Delivery - Repaired TV
        //         </div>
        //         {delivery &&
        //             <div>
        //                 <div>
        //                     <h4>TV packaging for delivery</h4>
        //                     <div style={{ display: 'flex' }}>
        //                         <input style={{ height: '15px', width: '15px' }} type='checkbox' />
        //                         <span style={{ marginRight: "25px" }}>Original packaging</span>
        //                     </div>
        //                     <div style={{ display: 'flex' }}>
        //                         <input style={{ height: '15px', width: '15px' }} type='checkbox' />New packaging material used
        //                     </div>
        //                     <table className='after-repair-table'>
        //                         <thead>
        //                             <tr>
        //                                 <th colSpan="2">Description</th>
        //                                 <th colSpan="2">PN</th>
        //                                 <th>Qty used</th>
        //                             </tr>
        //                         </thead>
        //                         <tbody>
        //                             <tr>
        //                                 <td colSpan="2"></td>
        //                                 <td colSpan="2"></td>
        //                                 <td></td>
        //                             </tr>
        //                             <tr>
        //                                 <td colSpan="2"></td>
        //                                 <td colSpan="2"></td>
        //                                 <td></td>
        //                             </tr>
        //                             <tr>
        //                                 <td colSpan="2"></td>
        //                                 <td colSpan="2"></td>
        //                                 <td></td>
        //                             </tr>
        //                         </tbody>
        //                     </table>
        //                 </div>
        //                 <div className="address">
        //                     <h4>Delivery address:</h4>
        //                     <div>
        //                         {detailedData.delivery_address.split(",").map((val, i) => {
        //                             return <p key={i}>{val}</p>;
        //                         })}
        //                     </div>
        //                     <div>
        //                         <p>Tel: {detailedData.delivery_phone}</p>
        //                         <p>Email: {detailedData.delivery_email}</p>
        //                     </div>
        //                 </div>

        //                 <div className="plan-request-date-container">
        //                     <h4>Delivery repaired TV:</h4>
        //                     <div className="plan-request-date">
        //                         <div className="plan-date">
        //                             {ownTransportToggle ?
        //                                 <button className="curPo confirm" onClick={handleOwnTransport}>Own Transport</button> :
        //                                 <div>
        //                                     <div style={{ display: "flex", alignItems: "baseline" }}>
        //                                         <p>Planned Date & Time:</p>
        //                                         <select></select>
        //                                         <button className="curPo confirm">Confirmed</button>
        //                                         <button className="curPo" onClick={handleOwnTransportCancel}>Cancel</button>
        //                                         <button
        //                                             className="curPo confirm selected-date"
        //                                             onClick={toggleDropdown}
        //                                         >
        //                                             Change planned date & time
        //                                         </button>
        //                                     </div>
        //                                     <div style={{ display: "flex", alignItems: "baseline" }}>
        //                                         <p>Delivered Date & Time:</p>
        //                                         <select></select>
        //                                         <button className="curPo confirm">Confirmed (142)</button>
        //                                         <button className="curPo">Cancel</button>
        //                                     </div>
        //                                 </div>}
        //                             {isOpen && (
        //                                 <div className="dropdown-calendar-container">
        //                                     <DatePicker
        //                                         selected={selectedDate}
        //                                         onChange={handleDateChange}
        //                                         inline
        //                                         required
        //                                         maxDate={currentDate}
        //                                         onClickOutside={() => setIsOpen(false)}
        //                                     />
        //                                 </div>
        //                             )}
        //                         </div>

        //                         <div>
        //                             {reqWHToggle ?
        //                                 <button className="curPo confirm" onClick={handleReqWHToggle}>Request WH Delivery</button> :
        //                                 <div style={{ display: "flex", alignItems: "baseline" }}>
        //                                     <p>Request Date & Time:</p>
        //                                     <select></select>
        //                                     <button className="curPo confirm">Confirm</button>
        //                                     <button className="curPo" onClick={handleReqWHToggleCancel}>Cancel</button>
        //                                 </div>}
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>}
        //     </div>
        // </div>


        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input className='checkbox-styled' type='checkbox' />
                <span style={{ marginRight: "25px" }}>Home Repair (no need for delivery)</span>
            </div>
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input className='checkbox-styled' type='checkbox' onChange={handleDelivery} />Delivery - Repaired TV
                </div>
                {delivery &&
                    <div>
                        <div>
                            <h4>TV packaging for delivery</h4>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input className='checkbox-styled' type='checkbox' />
                                <span style={{ marginRight: "25px" }}>Original packaging</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input className='checkbox-styled' type='checkbox' />New packaging material used
                            </div>
                            <table className='after-repair-table'>
                                <thead>
                                    <tr>
                                        <th colSpan="2">Description</th>
                                        <th colSpan="2">PN</th>
                                        <th>Qty used</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="2"></td>
                                        <td colSpan="2"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2"></td>
                                        <td colSpan="2"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2"></td>
                                        <td colSpan="2"></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="address">
                            <h4>Delivery address:</h4>
                            <div>
                                {/* {"4321 Oak Street, City, State, ZIP".split(",").map((val, i) => { */}
                                {`${detailedData.Firstline}, ${detailedData.Street}, ${detailedData.Postcode} ${detailedData.City}, ${detailedData.CountryId}`.split(",").map((val, i) => {
                                    return <p key={i}>{val}</p>;
                                })}
                            </div>
                            <div>
                                <p>Tel: {detailedData.Mobil}</p>
                                <p>Email: {detailedData.Email}</p>
                            </div>
                        </div>

                        <div className="plan-request-date-container">
                            <h4>Delivery repaired TV:</h4>
                            <div className="plan-request-date">
                                <div className="plan-date">
                                    {ownTransportToggle ?
                                        <button className="curPo confirm" onClick={handleOwnTransport}>Own Transport</button> :
                                        <div>
                                            <div style={{ display: "flex", alignItems: "baseline" }}>
                                                <p>Planned Date & Time:</p>
                                                <select className='select-styled'></select>
                                                <button className="curPo confirm">Confirmed</button>
                                                <button className="curPo cancel" onClick={handleOwnTransportCancel}>Cancel</button>
                                                <button
                                                    className="curPo confirm selected-date"
                                                    onClick={toggleDropdown}
                                                >
                                                    Change planned date & time
                                                </button>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "baseline" }}>
                                                <p>Delivered Date & Time:</p>
                                                <select className='select-styled'></select>
                                                <button className="curPo confirm">Confirmed (142)</button>
                                                <button className="curPo cancel">Cancel</button>
                                            </div>
                                        </div>}
                                </div>

                                <div>
                                    {reqWHToggle ?
                                        <button className="curPo confirm" onClick={handleReqWHToggle}>Request WH Delivery</button> :
                                        <div style={{ display: "flex", alignItems: "baseline" }}>
                                            <p>Request Date & Time:</p>
                                            <select className='select-styled'></select>
                                            <button className="curPo confirm">Confirm</button>
                                            <button className="curPo cancel" onClick={handleReqWHToggleCancel}>Cancel</button>
                                        </div>}
                                </div>
                            </div>
                            <div>
                                {isOpen && (
                                        <div className="dropdown-calendar-container">
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange}
                                                inline
                                                required
                                                maxDate={currentDate}
                                                onClickOutside={() => setIsOpen(false)}
                                            />
                                        </div>
                                    )}
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input className='checkbox-styled' type='checkbox' onChange={handleDeliveryNSE} />Delivery - NSE TV
                        </div>
                        {deliveryNSE &&
                            <div>
                                <div className="address">
                                    <h4>Delivery address:</h4>
                                    <div>
                                        {/* {"4321 Oak Street, City, State, ZIP".split(",").map((val, i) => { */}
                                        {`${detailedData.Firstline}, ${detailedData.Street}, ${detailedData.Postcode} ${detailedData.City}, ${detailedData.CountryId}`.split(",").map((val, i) => {
                                            return <p key={i}>{val}</p>;
                                        })}
                                    </div>
                                    <div>
                                        <p>Tel: {detailedData.Mobil}</p>
                                        <p>Email: {detailedData.Email}</p>
                                    </div>
                                </div>

                                <div className="plan-request-date-container">
                                    <h4>Delivery NSE TV:</h4>
                                    <div className="plan-request-date">
                                        <div className="plan-date">
                                            {ownTransportToggle ?
                                                <button className="curPo confirm">Own Transport</button> :
                                                <div>
                                                    <div style={{ display: "flex", alignItems: "baseline" }}>
                                                        <p>Planned Date & Time:</p>
                                                        <select className='select-styled'></select>
                                                        <button className="curPo confirm">Confirmed</button>
                                                        <button className="curPo cancel" onClick={handleOwnTransportCancel}>Cancel</button>
                                                        <button
                                                            className="curPo confirm selected-date"
                                                            onClick={toggleDropdown}
                                                        >
                                                            Change planned date & time
                                                        </button>
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "baseline" }}>
                                                        <p>Delivered Date & Time:</p>
                                                        <select className='select-styled'></select>
                                                        <button className="curPo confirm">Confirmed (160)</button>
                                                        <button className="curPo cancel">Cancel</button>
                                                    </div>
                                                </div>}
                                            {isOpen && (
                                                <div className="dropdown-calendar-container">
                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={handleDateChange}
                                                        inline
                                                        required
                                                        maxDate={currentDate}
                                                        onClickOutside={() => setIsOpen(false)}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div>
                                            {reqWHToggle ?
                                                <button className="curPo confirm">Request WH Delivery</button> :
                                                <div style={{ display: "flex", alignItems: "baseline" }}>
                                                    <p>Request Date & Time:</p>
                                                    <select className='select-styled'></select>
                                                    <button className="curPo confirm">Confirm</button>
                                                    <button className="curPo cancel" onClick={handleReqWHToggleCancel}>Cancel</button>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                                </div>}
                            </div>}
                    </div>
        </div>
            )
}

            export default Delivery
