import React from 'react'

function Reports() {
  return (
    <div style={{ marginTop: '2%', padding: '0.5% 5px' }}>
      Report Tab
    </div>
  )
}

export default Reports
