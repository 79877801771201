import React from 'react'

function AfterRepair() {
  return (
    <div>
      <table className='after-repair-table'>
        <thead>
          <tr>
            <th>#</th>
            <th colSpan="2">Function</th>
            <th colSpan="2">Result</th>
            <th>Pass (Y/N)</th>
            <th>Operator</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td colSpan="2"></td>
            <td colSpan="2"></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td colSpan="2"></td>
            <td colSpan="2"></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td colSpan="2"></td>
            <td colSpan="2"></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default AfterRepair
