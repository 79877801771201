import React, { useState } from 'react'
import ConfirmationModal from './ConfirmationModal';

function SpOrder() {
    const [orderNSETV, setOrderNSETV] = useState(false);
    const [checkSendPO, setCheckSendPO] = useState(false);
    const [checkDownloadPO, setCheckDownloadPO] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const handleSendPO = () => {
        if (!checkSendPO) {
            setIsOpen1(true);
        } else {
            setIsOpen1(false)
            setCheckSendPO(false)
        }
    }

    const handleDownloadPO = () => {
        if (!checkDownloadPO) {
            setIsOpen2(true);
        } else {
            setIsOpen2(false)
            setCheckDownloadPO(false)
        }
    }

    const handleConfirm1 = () => {
        setCheckSendPO(true)
        setIsOpen1(false);
    };

    const handleConfirm2 = () => {
        setCheckDownloadPO(true)
        setIsOpen2(false);
    };

    const handleCancel = () => {
        setIsOpen1(false);
        setIsOpen2(false);
    };

    const handletoggle = () => {
        setOrderNSETV(!orderNSETV)
    }

    return (
        <div>
            <ul className="spare-parts-list">
                <li>
                    <span style={{ fontWeight: '600' }}>Parts Ordering</span>
                    <span> Description</span>
                </li>
                <li>
                    <span>Spare part 1</span>
                    <select>
                        <option value=""></option>
                    </select>
                    <div style={{ gridColumn: 'span 2', width: '50%', justifySelf: 'center' }}>Order qty: <input style={{ width: '50%' }}></input></div>
                </li>
                <li>
                    <span>Spare part 2</span>
                    <select>
                        <option value=""></option>
                    </select>
                </li>
                <li>
                    <span>Spare part 3</span>
                    <select>
                        <option value=""></option>
                    </select>
                </li>
                <li>
                    <span>Spare part 4</span>
                    <select>
                        <option value=""></option>
                    </select>
                </li>
                <li style={{ margin: '2% 0' }}>
                    <span>SPMS order number:</span>
                    <input type='text' style={{ gridColumn: 'span 2', width: '75%' }}></input>
                    <div style={{ gridColumn: 'span 4', gridRow: 'span 2' }}>
                        <div style={{ display: 'flex' }}>
                            <input className='checkbox-styled' style={{ height: '15px', width: '15px' }} type='checkbox' checked={checkSendPO} onChange={handleSendPO} />
                            <span style={{ marginRight: "25px" }}>Send PO to SPMS</span>
                            <ConfirmationModal
                                isOpen={isOpen1}
                                message="Are you sure to send?"
                                onConfirm={handleConfirm1}
                                onCancel={handleCancel}
                            />
                            {/* {checkSendPO &&
                                    <div>
                                        <button type='submit' className="curPo confirm">Confirm (356)</button>
                                        <button type='reset' className="curPo">Cancel</button>
                                    </div>} */}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <input className='checkbox-styled' style={{ height: '15px', width: '15px' }} type='checkbox' checked={checkDownloadPO} onChange={handleDownloadPO} />Download PO file
                            <ConfirmationModal
                                isOpen={isOpen2}
                                message="Are you sure download the file?"
                                onConfirm={handleConfirm2}
                                onCancel={handleCancel}
                            />
                            {/* {checkDownloadPO &&
                                    <div>
                                        <span style={{ marginLeft: "25px" }}>PO ordered on SPMS manually</span>
                                        <button type='submit' className="curPo confirm">Confirm (356)</button>
                                        <button type='reset' className="curPo">Cancel</button>
                                    </div>} */}
                        </div>
                    </div>
                </li>
            </ul>

            <ul className="spare-parts-list">
                <li>
                    <span style={{ fontWeight: '600' }}>Delivery status</span>
                    <span> Description</span>
                    <span>TPV PN</span>
                    <span style={{ gridColumn: 'span 2', width: '50%', justifySelf: 'center' }}>Substitution PN</span>
                    <span>RID</span>
                    <span>Section</span>
                    <span style={{ fontWeight: '600' }}>Delivery Status</span>
                </li>
                <li>
                    <span>Spare part 1</span>
                    <span style={{ border: "solid 1px black", width: '50%' }}>Mainboard</span>
                    <span style={{ border: "solid 1px black" }}>HGHJKHG765HG</span>
                    <span style={{ border: "solid 1px black", gridColumn: 'span 2', width: '50%', justifySelf: 'center' }}></span>
                    <span style={{ border: "solid 1px black", width: '50%' }}></span>
                    <span style={{ border: "solid 1px black", width: '50%' }}>SYS</span>
                    <span style={{ border: "solid 1px black" }}>60:shipped</span>
                </li>
                <li>
                    <span>Spare part 2</span>
                </li>
                <li>
                    <span>Spare part 3</span>
                </li>
                <li>
                    <span>Spare part 4</span>
                </li>
            </ul>



            <button onClick={handletoggle} style={{ margin: '50px 0' }}>Ordering NSE TV +</button>
            {orderNSETV && <div>
                <ul className="spare-parts-list">
                    <li>
                        <span></span>
                        <span>TPV PN (18AC)</span>
                        <span style={{ width: '50%', justifySelf: 'center' }}>Grade</span>
                    </li>
                    <li>
                        <span>TV part number</span>
                        <select>
                            <option value=""></option>
                        </select>
                        <select style={{ width: '50%', justifySelf: 'center' }}></select>
                    </li>
                    <li style={{ margin: '2% 0' }}>
                        <span>SPMS order number:</span>
                        <input type='text' style={{ gridColumn: 'span 2', width: '75%' }}></input>
                        <div style={{ gridColumn: 'span 4', gridRow: 'span 2' }}>
                            <div style={{ display: 'flex' }}>
                                <input className='checkbox-styled' style={{ height: '15px', width: '15px' }} type='checkbox' checked={checkSendPO} onChange={handleSendPO} />
                                <span style={{ marginRight: "25px" }}>Send PO to SPMS</span>
                                {/* <button type='submit' className="curPo confirm">Confirm (346)</button>
                            <button type='reset' className="curPo">Cancel</button> */}
                            </div>
                            <div style={{display: 'flex'}}>
                                <input className='checkbox-styled' style={{ height: '15px', width: '15px' }} type='checkbox' checked={checkDownloadPO} onChange={handleDownloadPO} />
                                <span>Create PO as excel file</span>
                                {/* <span style={{ marginLeft: "25px" }}>PO ordered on SPMS manually</span>
                            <button type='submit' className="curPo confirm">Confirm (346)</button>
                            <button type='reset' className="curPo">Cancel</button> */}
                            </div>
                        </div>
                    </li>
                </ul>

                <ul className="spare-parts-list">
                    <li>
                        <span style={{ fontWeight: '600' }}>Delivery status</span>
                        <span>TPV PN (18AC)</span>
                        <span style={{ gridColumn: 'span 2', width: '50%', justifySelf: 'center' }}>Grade</span>
                        <span>RID</span>
                        <span style={{ fontWeight: '600' }}>Delivery Status</span>
                    </li>
                    <li>
                        <span>Spare part 1</span>
                        <span style={{ border: "solid 1px black" }}></span>
                        <span style={{ border: "solid 1px black", gridColumn: 'span 2', width: '50%', justifySelf: 'center' }}></span>
                        <span style={{ border: "solid 1px black", width: '50%' }}></span>
                        <span style={{ border: "solid 1px black" }}>60:shipped</span>
                    </li>
                </ul>
            </div>}
        </div>
    )
}

export default SpOrder
