import React from 'react'

function RepairHistory() {
  const randomRepairHistoryData = [{
    Date: '16.12.2019',
    Error_Description: 'xxxx', 
    Section_Code: 'LCD',
    Repair_Code: 'AX    Replacement by a "scrap" component'
  },
  {
    Date: '05.10.2018',
    Error_Description: 'xxxxxx', 
    Section_Code: 'LCD',
    Repair_Code: 'A2    Module replacement'
  }
]
  return (
    <div>
      <div className="deatiled-info-list">
        {randomRepairHistoryData.map((data, i) => (
          <ul key={i}>
          <li className="listItemStyle">
            <span >{i+1}. Date:</span>{" "}
            {data.Date}
          </li>
          <li className="listItemStyle">
            <span >{i+1}. Error Description:</span>{" "}
            {data.Error_Description}
          </li>
          <li className="listItemStyle">
            <span >{i+1}. Section Code:</span>{" "}
            {data.Section_Code}
          </li>
          <li className="listItemStyle">
            <span >{i+1}. Repair Code:</span>{" "}
            {data.Repair_Code}
          </li>
        </ul>
        ))}
        
      </div>
    </div>
  )
}

export default RepairHistory
