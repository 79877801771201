import React from "react";

function SingleData({ infotipData }) {

  return (
    // <div className="wo-infotip">
    //   <table style={{fontSize: "15px", border: "solid 2px rgb(172, 172, 255)", padding: "1%", margin: "0"}}>
    //     <thead style={{fontSize: "small"}}>
    //       <tr className="table-header-row">
    //         <th>Seq#</th>
    //         <th>Order Date & Time</th>
    //         {infotipData.Infotip_WO_Number && <th>Infotip WO number</th>}
    //         <th>WP WO Number</th>
    //         <th>Handling Type</th>
    //         <th>Product Name</th>
    //         <th>Serial Number</th>
    //         <th>Country</th>
    //         <th>Workshop</th>
    //         <th>ASP</th>
    //         <th>Infotip Status Woodpecker</th>
    //         <th>Creator Role</th>
    //         <th>Type of Order</th>
    //         <th>RR</th>
    //       </tr>
    //       <tr>
    //         <td colSpan="14">
    //           <hr />
    //         </td>
    //       </tr>
    //     </thead>
    //     <tbody>
    //           <tr className="table-body-row" key={infotipData._id}>
    //             <td>1</td>
    //             <td>{infotipData.Order_Date_Time}</td>
    //             {infotipData.Infotip_WO_Number && <td>{infotipData.Infotip_WO_Number}</td>}
    //             <td className="uline">{infotipData.WP_WO_Number}</td>
    //             {/* <td>{(infotipData.Handling_Type).split(':')[0]}</td> */}
    //             <td>{typeof infotipData.Handling_Type === 'string' ? infotipData.Handling_Type.split(':')[0] : infotipData.Handling_Type}</td>
    //             <td>{infotipData.Product_Name}</td>
    //             <td>{infotipData.Serial_Number}</td>
    //             {/* <td>{(infotipData.Country).split(' ')[0]}</td> */}
    //             <td>{typeof infotipData.Country === 'string' ? infotipData.Country.split(' ')[0] : infotipData.Country}</td>
    //             <td>{infotipData.Workshop}</td>
    //             <td>{infotipData.ASP}</td>
    //             {/* <td className={(infotipData.Infotip_Status).split(':')[0] === "96" ? "green-status" : "red-status"}>{infotipData.Infotip_Status}</td> */}
    //             <td className={typeof infotipData.Infotip_Status === 'string' ? (infotipData.Infotip_Status.split(':')[0] === "96" ? "green-status" : "red-status"): infotipData.Infotip_Status}>{infotipData.Infotip_Status}</td>                
    //             <td>{infotipData.Creator_Role}</td>
    //             <td>{infotipData.Type_of_Order}</td>
    //             <td>{infotipData.repeat_repair}</td>
    //           </tr>
    //     </tbody>
    //   </table>
    // </div>


    <div className="wo-infotip">
      {infotipData && <table style={{ fontSize: "15px", border: "solid 2px rgb(172, 172, 255)", padding: "1%", margin: "0" }}>
        <thead style={{ fontSize: "small" }}>
          <tr className="table-header-row">
            <th>Seq#</th>
            <th>Order Date & Time</th>
            {infotipData.OrderName && <th>Infotip WO number</th>}
            {infotipData.WP_WO_Number && <th>WP WO Number</th>}
            <th>Handling Type</th>
            <th>Product Name</th>
            <th>Serial Number</th>
            <th>Country</th>
            <th>Workshop</th>
            <th>ASP</th>
            <th>Infotip Status Woodpecker</th>
            {/* <th>Creator Role</th> */}
            <th>Assignee</th>
            <th>Type of Order</th>
            <th>RR</th>
            <th>TAT</th>
          </tr>
          <tr>
            <td colSpan="14">
              <hr />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="table-body-row" key={infotipData.OrderName}>
            <td>1</td>
            <td>{infotipData.OrderDate}</td>
            {infotipData.OrderName && <td>{infotipData.OrderName}</td>}
            {infotipData.WP_WO_Number && <td className="uline">{infotipData.WP_WO_Number}</td>}
            {/* <td>{typeof infotipData.Handling_Type === 'string' ? infotipData.HandlingType.split(':')[0] : infotipData.HandlingType}</td> */}
            <td>{infotipData.HandlingType}</td>
            <td>{infotipData.ProductName}</td>
            <td>{infotipData.SerialNumber}</td>
            {/* <td>{typeof infotipData.Country === 'string' ? infotipData.Country.split(' ')[0] : infotipData.CountryId}</td> */}
            <td>{infotipData.CountryId}</td>
            <td>{infotipData.WorkshopName}</td>
            {/* <td>{infotipData.ASP}</td> */}
            <td>xxxx</td>
            {/* <td className={typeof infotipData.Infotip_Status === 'string' ? (infotipData.Infotip_Status.split(':')[0] === "96" ? "green-status" : "red-status") : infotipData.Infotip_Status}>{infotipData.Infotip_Status}</td> */}
            <td className={infotipData.OrderState === "96" ? "green-status" : "red-status"}>{infotipData.OrderState}</td>
            {/* <td>{infotipData.CreatorRole}</td> */}
            <td>xxxx</td>
            <td>{infotipData.TypeOfOrder}</td>
            {/* <td>{infotipData.repeat_repair}</td> */}
            <td>xxxx</td>
            <td>{infotipData.TAT}</td>
          </tr>
        </tbody>
      </table>}
    </div>
  );
}

export default SingleData;
