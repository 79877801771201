import React from 'react'

function BuddyTool() {
  return (
    <div>
      Buddy Tool
    </div>
  )
}

export default BuddyTool
