import React, { useState } from 'react';
import '../styles/UserRegistration.css'
import { Footer } from "./Footer"
import axios from "axios";

const UserRegistration = () => {
    const [data, setData] = useState({
        username: '',
        password: '',
        confirmpassword: '',
        userType: '',
        workshopname: '',
        country: '',
        site: ''
    });

    const [error, setError] = useState({
        usernameNotValid: false,
        usernameNotValidText: '',
        passwordNotValid: false,
        passwordNotValidText: '',
        confirmpasswordNotValid: false,
        confirmpasswordNotValidText: '',
        usertypeNotValid: false,
        usertypeNotValidText: '',
        workshopnameNotValid: false,
        workshopnameNotValidText: '',
        countryNotValid: false,
        countryNotValidText: '',
        siteNotValid: false,
        siteNotValidText: ''
    });

    const [showRegisterStatus, setShowRegisterStatus] = useState(false);
    const [registerStatus, setRegisterStatus] = useState('');
    const [registerStatusMessage, setRegisterStatusMessage] = useState('');
    const [hide, setHide] = useState(true);
    const [hide1, setHide1] = useState(true);
    const [infop, setInfop] = useState(false);
    const [multiSites, setMultiSites] = useState([]);
    const [featureSelection, setFeatureSelection] = useState([]);

    const userTypes = ['TPV Leader', 'TPV Technician', 'ASC Leader', 'ASC Technician', 'Manager'];
    const workshopNames = ['Gorzow'];
    const countries = ['Country1', 'Country2'];
    const sites = [
        { site_name: 'ASC ABC', userid: 'ASC ABC' },
    ];
    const features = ['Feature1', 'Feature2'];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleRegister = async () => {
        // Validation logic goes here
        // Example validation:
        let hasError = false;
        const newError = {};

        if (!data.username) {
            newError.usernameNotValid = true;
            newError.usernameNotValidText = 'Username is required';
            hasError = true;
        }

        if (!data.password) {
            newError.passwordNotValid = true;
            newError.passwordNotValidText = 'Password is required';
            hasError = true;
        }

        if (!data.confirmpassword) {
            newError.confirmpasswordNotValid = true;
            newError.confirmpasswordNotValidText = 'Confirm Password is required';
            hasError = true;
        }

        if (data.password !== data.confirmpassword) {
            newError.confirmpasswordNotValid = true;
            newError.confirmpasswordNotValidText = 'Passwords do not match';
            hasError = true;
        }

        if (!data.userType) {
            newError.usertypeNotValid = true;
            newError.usertypeNotValidText = 'User Type is required';
            hasError = true;
        }
        if (!data.workshopname) {
            newError.workshopnameNotValid = true;
            newError.workshopnameNotValidText = 'Workshop Name is required';
            hasError = true;
          }


        if (hasError) {
            setError(newError);
            return;
        }

        try {
            const response = await axios.post('https://wprepairacc.tpvai.com/api/registeruser', data);
            console.log(response.data);
            if (response.data.status == "success") {
                setShowRegisterStatus(true);
                setRegisterStatus('success');
                setRegisterStatusMessage('User registered successfully');
            } else {
                console.error('Error registering user:', error);
                setShowRegisterStatus(true);
                setRegisterStatus('error');
                setRegisterStatusMessage('Failed to register user');
            }
        } catch (error) {
            console.error('Error registering user:', error);
            setShowRegisterStatus(true);
            setRegisterStatus('error');
            setRegisterStatusMessage('Failed to register user');
        }
    };


    const generatePass = () => {
        const length = 6; // Define the desired length of the password
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
        let password = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            password += charset.charAt(Math.floor(Math.random() * n));
        }
        setData(prevData => ({ ...prevData, password }));
    };

    const clearFields = () => {
        // Clear specific fields logic here
    };

    const info = () => {
        setInfop(!infop);
    };

    return (
        <>
            <div className="container1">
                <h2>User Registration</h2>
                <br />
                {showRegisterStatus && (
                    <div className="row">
                        <label className="control-label col-xs-3"></label>
                        {registerStatus === 'success' ? (
                            <span style={{ color: 'green' }}>{registerStatusMessage}</span>
                        ) : (
                            <span style={{ color: 'red' }}>{registerStatusMessage}</span>
                        )}
                    </div>
                )}

                <div className="form-group row" >
                    <div className="col-xs-4">
                        <label><b>Username</b></label>
                        <input
                            type="text"
                            className="form-control input-sm"
                            placeholder="Username"
                            name="username"
                            value={data.username}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-xs-3"></label>
                    {error.usernameNotValid && <span style={{ color: 'red' }}>{error.usernameNotValidText}</span>}
                </div>
                <br />
                <div className="form-group row">
                    <div className="col-xs-4">
                        <label>
                            <b>Password</b> &nbsp;&nbsp;
                            <a onClick={generatePass} className='link'>Generate Password</a>
                        </label>
                        <input
                            type={hide ? 'password' : 'text'}
                            className="form-control input-sm"
                            placeholder="Enter Password"
                            name="password"
                            value={data.password}
                            onChange={handleInputChange}
                        />
                    </div>
                    <img
                        id="eye"
                        className="eye"
                        src={hide ? '../assets/Group265.svg' : '../assets/Group1567.svg'}
                        onClick={() => setHide(!hide)}
                        style={{ cursor: 'pointer' }}
                        alt="Toggle Visibility"
                    />
                    <img
                        src="assets/icons8-info.svg"
                        className="info"
                        onClick={info}
                        style={{ width: 20, height: 20, cursor: 'pointer' }}
                        alt="Info"
                    />
                </div>
                <div className="row">
                    <label className="control-label col-xs-3"></label>
                    {error.passwordNotValid && <span style={{ color: 'red' }}>{error.passwordNotValidText}</span>}
                </div>
                {/* <br /> */}
                <div className="form-group row">
                    <div className="col-xs-4">
                        <label><b>Confirm password</b></label>
                        <input
                            type={hide1 ? 'password' : 'text'}
                            className="form-control input-sm"
                            placeholder="Confirm Password"
                            name="confirmpassword"
                            value={data.confirmpassword}
                            onChange={handleInputChange}
                        />
                    </div>
                    <img
                        id="eye"
                        className="eye1"
                        src={hide1 ? 'assets/Group265.svg' : 'assets/Group1567.svg'}
                        onClick={() => setHide1(!hide1)}
                        style={{ cursor: 'pointer' }}
                        alt="Toggle Visibility"
                    />
                    <img
                        src="assets/icons8-info.svg"
                        className="info1"
                        onClick={info}
                        style={{ width: 20, height: 20, cursor: 'pointer' }}
                        alt="Info"
                    />
                </div>
                <div className="row">
                    <label className="control-label col-xs-3"></label>
                    {error.confirmpasswordNotValid && <span style={{ color: 'red' }}>{error.confirmpasswordNotValidText}</span>}
                </div>
                {/* <br /> */}
                <div className="form-group row">
                    <div className="col-xs-4">
                        <label><b>User type</b></label>
                        <select
                            className="form-control input-sm"
                            name="userType"
                            value={data.userType}
                            onChange={handleInputChange}
                        >
                            <option value=""></option>
                            {userTypes.map((item) => (
                                <option key={item} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-xs-3"></label>
                    {error.usertypeNotValid && <span style={{ color: 'red' }}>{error.usertypeNotValidText}</span>}
                </div>
                <br />
                {(
                    <>
                        <div className="form-group row">
                            <div className="col-xs-4">
                                <label><b>Workshop name</b></label>
                                <select
                                    className="form-control input-sm"
                                    name="workshopname"
                                    value={data.workshopname}
                                    onChange={handleInputChange}
                                >
                                    <option value=""></option>
                                    {workshopNames.map((item) => (
                                        <option key={item} value={item}>{item}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <label className="control-label col-xs-3"></label>
                            {error.workshopnameNotValid && <span style={{ color: 'red' }}>{error.workshopnameNotValidText}</span>}
                        </div>
                    </>
                )}
                <br />
                {(data.userType == 'ASC Leader' || data.userType == 'ASC Technician') && (
                    <div className="form-group row">
                        <div className="col-xs-4">
                            <label><b>ASC</b></label>
                            <select
                                className="form-control input-sm"
                                name="site"
                                value={data.site}
                                onChange={handleInputChange}
                            >
                                <option value=""></option>
                                {sites.map((item) => (
                                    <option key={item.site_name} value={item.site_name}>{item.site_name}</option>
                                ))}
                            </select>
                        </div>
                        {/* {data.site && (
            <div>
              <label><b>GWED UserID</b></label>
              {sites
                .filter((item) => item.site_name === data.site)
                .map((item) => (
                  <p key={item.site_name}>{item.userid}</p>
                ))}
            </div>
          )} */}
                    </div>
                )}

                <div className="form-group row mt-4">
                    <div className="col-xs-4">
                        <button className="btn btn-primary col-xs-2" onClick={handleRegister}>Register</button>
                    </div>
                </div>
                {infop && (
                    <div className="form-popup" id="myForm">
                        <div className="close-btn-wrapper">
                            <button className="close-btn2" onClick={info}>X</button>
                        </div>
                        <form className="form-container">
                            <div>
                                <p><b>Password should follow these rules</b></p>
                                <div>{/* Display password rules here */}</div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default UserRegistration;
