import React, { useState } from 'react'

function VideoCall() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneCode, setPhoneCode] = useState('');
    const [customerLanguage, setCustomerLanguage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <div>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', width: "40%", border: "solid 2px rgb(98, 98, 243)", padding: "0 2%" }}>
                <label style={{ alignSelf: "center", padding: "2%", fontWeight: '600', fontSize: 'medium' }}>Enter Phone Number</label>
                <hr style={{width: "100%", margin: '10px 0 20px 0'}}></hr>
                <div>
                    <div style={{ display: 'grid', gridTemplateColumns: "repeat(3, 1fr)"}}>
                        <label>Customer Phone</label>
                        <select value={phoneCode} onChange={(e) => setPhoneCode(e.target.value)} required>
                            <option value="">Select phoneCode</option>
                            <option value="+91">India</option>
                            <option value="+34">Spain</option>
                            <option value="+33">France</option>
                        </select>
                            <input type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required></input>
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: "repeat(3, 1fr)" }}>
                        <label>Customer Preferred Language</label>
                        <select value={customerLanguage} onChange={(e) => setCustomerLanguage(e.target.value)} required>
                            <option value="">Select Language</option>
                            <option value="English">English</option>
                            <option value="Spanish">Spanish</option>
                            <option value="French">French</option>
                        </select>
                    </div>
                </div>
                <button style={{ width: "100px", margin: "5px", alignSelf: "center" }} className='filled-button' type='submit'>Submit</button>
            </form>
            <table style={{ borderSpacing: 20 }}>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>WO Number</th>
                        <th>Photo Link</th>
                        <th>Video Link</th>
                        <th>Phone Number</th>
                    </tr>
                </thead>
            </table>
        </div>
    )
}

export default VideoCall
