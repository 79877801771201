import React, { useState } from "react";
import HomeVisit from "./HomeVisit";
import PickUp from "./PickUp";

function HomePick({ detailedData, textOptions, getPythonData, setLoading }) {
  const [toggleHomeVisit, setToggleHomeVisit] = useState(false);
  const [togglePickUp, setTogglePickUp] = useState(false);

  const handleClickAssign = () => {
    setToggleHomeVisit(!toggleHomeVisit);
  };

  const handleClickOrder = () => {
    setTogglePickUp(!togglePickUp);
  };
  return (
    // <div>
    //   <div className="toggle-box">
    //     <button onClick={handleClickAssign}>-</button>
    //     <h5>Home Repair Visit Schedule Arrangement</h5>
    //   </div>
    //   {toggleHomeVisit && <HomeVisit detailedData={detailedData}/>}
    //   <div className="toggle-box">
    //     <button onClick={handleClickOrder}>-</button>
    //     <h5>Pick Up</h5>
    //   </div>
    //   {togglePickUp && <PickUp detailedData={detailedData}/>}
    // </div>

    <div>
      <div className="toggle-box">
        <button onClick={handleClickAssign}>-</button>
        <h5>Home Repair Visit Schedule Arrangement</h5>
      </div>
      {toggleHomeVisit && <HomeVisit detailedData={detailedData} />}
      <div className="toggle-box">
        <button onClick={handleClickOrder}>-</button>
        <h5>Pick Up</h5>
      </div>
      {togglePickUp && <PickUp detailedData={detailedData} textOptions={textOptions} getPythonData={getPythonData} setLoading={setLoading}/>}
    </div>
  );
}

export default HomePick;
