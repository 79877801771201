import React, { useState } from 'react'
import ConfirmationModal from './ConfirmationModal';

function NSEAuthReq() {
    const [checkSendClaim, setCheckSendClaim] = useState(false);
    const [checkDownloadClaim, setCheckDownloadClaim] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const handleSendClaim = () => {
        if (!checkSendClaim) {
            setIsOpen1(true);
        } else {
            setIsOpen1(false)
            setCheckSendClaim(false)
        }
    }

    const handleDownloadClaim = () => {
        if (!checkDownloadClaim) {
            setIsOpen2(true);
        } else {
            setIsOpen2(false)
            setCheckDownloadClaim(false)
        }
    }

    const handleConfirm1 = () => {
        setCheckSendClaim(true)
        setIsOpen1(false);
    };

    const handleConfirm2 = () => {
        setCheckDownloadClaim(true)
        setIsOpen2(false);
    };

    const handleCancel = () => {
        setIsOpen1(false);
        setIsOpen2(false);
    };

    return (
        <div>
            <div>
                <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                    <h4>NSE - Consumer Service Solution</h4>
                    <select className='select-styled' style={{ height: 'fit-content' }}></select>
                </div>
                <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                    <h4>NSE - Reason</h4>
                    <select className='select-styled' style={{ height: 'fit-content' }}></select>
                </div>
                <h4>Attachment:</h4>
            </div>
            <div style={{ margin: '2% 0', display: 'flex', gap: '10%', alignItems: 'baseline' }}>
                <h4>A1 Claim</h4>
                <div style={{ gridColumn: 'span 4', gridRow: 'span 2' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input className='checkbox-styled' type='checkbox' checked={checkSendClaim} onChange={handleSendClaim} />
                        <span style={{ marginRight: "25px" }}>Send A1 claim to TCA</span>
                        <ConfirmationModal
                            isOpen={isOpen1}
                            message="Are you sure to send the claim?"
                            onConfirm={handleConfirm1}
                            onCancel={handleCancel}
                        />
                        {/* {checkSendClaim &&
                            <div>
                                <button type='submit' className="curPo confirm">Confirm (376)</button>
                                <button type='reset' className="curPo">Cancel</button>
                            </div>} */}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input className='checkbox-styled' type='checkbox' checked={checkDownloadClaim} onChange={handleDownloadClaim} />Download A1 claim file
                        <ConfirmationModal
                            isOpen={isOpen2}
                            message="Are you sure download the file?"
                            onConfirm={handleConfirm2}
                            onCancel={handleCancel}
                        />
                        {/* {checkDownloadClaim &&
                            <div>
                                <span style={{ marginLeft: "25px" }}>Claim submitted to TCA manually</span>
                                <button type='submit' className="curPo confirm">Confirm (376)</button>
                                <button type='reset' className="curPo">Cancel</button>
                            </div>} */}
                    </div>
                </div>
            </div>
            <div>
                <h4>A1 claim URN</h4>
            </div>
        </div>
    )
}

export default NSEAuthReq
