import React, { useState } from 'react'
import './Options.css'
import OrderCompletion from './OrderCompletion'
import AssignTechnician from './AssignTechnician'
import HandlingStatus from './HandlingStatus';

function Options({ detailedData }) {
  const [toggleAssignTechnician, setToggleAssignTechnician] = useState(false);
  const [toggleOrderCompletion, setToggleOrderCompletion] = useState(false);
  const [toggleHandlingStatus, setToggleHandlingStatus] = useState(false);

  const handleClickAssign = () => {
    setToggleAssignTechnician(!toggleAssignTechnician);
  }

  const handleClickOrder = () => {
    setToggleOrderCompletion(!toggleOrderCompletion);
  }

  const handleClickStatus = () => {
    setToggleHandlingStatus(!toggleHandlingStatus);
  }

  return (
    <div>
      <div className='toggle-box'>
        <button onClick={handleClickAssign}>-</button>
        <h5>Assigning WO</h5>
      </div>
      {toggleAssignTechnician && <AssignTechnician detailedData={ detailedData }/>}
      <div className='toggle-box'>
        <button onClick={handleClickOrder}>-</button>
        <h5>WO Service Completion Check (required for TCA submission)</h5>
      </div>
      {toggleOrderCompletion && <OrderCompletion/>}
      <div className='toggle-box'>
        <button onClick={handleClickStatus}>-</button>
        <h5>WO Handling History</h5>
      </div>
      {toggleHandlingStatus && <HandlingStatus/>}
    </div>
  )
}

export default Options
