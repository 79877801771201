// import React, { useState } from "react";
// import "./App.css";
// import App from './App'
// import Reports from "./components/Reports";
// import {Footer} from "./components/Footer"

// // Tab component
// const Tab = ({ id, isActive, onClick }) => (
//     <div className={`tab-panel`}>
//         <span className={`${isActive ? "active-panel" : ""} curPo`} onClick={onClick}>{id}</span>
//     </div>
// );

// function Home() {
//     const position = 'relative'
//     const tabs = [{ id: "WO Overview" }, { id: "Reports" }]
//     const [activeTab, setActiveTab] = useState("WO Overview");

//     const renderContent = (tab) => {
//         switch (tab.id) {
//             case "WO Overview":
//                 return <App/>
//             case "Reports":
//                 return <Reports/>
//             default:
//                 return null
//         }
//     };

//     const handleClick = () => {
//         window.open('https://woodpecker.tpvai.com/login', '_blank');
//     }

//     return (
//         <>
//         <div style={{ display: "flex" }}>

//             <div style={{ position: "fixed" }}>
//                 <div style={{marginTop: '25%', width: "140px" }}>
//                     {tabs.map((tab) => (
//                         <Tab
//                             key={tab.id}
//                             id={tab.id}
//                             isActive={activeTab === tab.id}
//                             onClick={() => {
//                                 setActiveTab(tab.id);
//                             }}
//                         />
//                     ))}
//                 </div>
//                 <div className="tab-panel curPo" onClick={handleClick}>Troubleshooting Pageee</div>
//             </div>

//             <div style={{width: '100%'}}>
//                 {tabs.map((tab) => {
//                     return (
//                         <div
//                             key={tab.id}
//                             style={{ marginLeft: "145px", display: activeTab === tab.id ? "block" : "none" }}
//                         >
//                             {renderContent(tab)}
//                         </div>
//                     );
//                 })}
//             </div>
//         </div>
//         <Footer /> 
//         </>

//     );
// }

// export default Home;

import { Outlet } from 'react-router-dom';
import { Header } from './components/Header';


function Home() {
    return (
        <>
        <Header  />
        <Outlet />
        {/* <Footer position={position} /> */}
        </>
    )
}

export default Home;