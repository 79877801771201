import React, { useState } from 'react'
import ConfirmationModal from './ConfirmationModal';

function TcaClaim() {
    const [checkSendClaim, setCheckSendClaim] = useState(false);
    const [checkDownloadClaim, setCheckDownloadClaim] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const handleSendClaim = () => {
        if (!checkSendClaim) {
            setIsOpen1(true);
        } else {
            setIsOpen1(false)
            setCheckSendClaim(false)
        }
    }

    const handleDownloadClaim = () => {
        if (!checkDownloadClaim) {
            setIsOpen2(true);
        } else {
            setIsOpen2(false)
            setCheckDownloadClaim(false)
        }
    }

    const handleConfirm1 = () => {
        setCheckSendClaim(true)
        setIsOpen1(false);
    };

    const handleConfirm2 = () => {
        setCheckDownloadClaim(true)
        setIsOpen2(false);
    };

    const handleCancel = () => {
        setIsOpen1(false);
        setIsOpen2(false);
    };

    return (
        <div>
            <div style={{ display: 'flex', gap: '10%', alignItems: 'baseline' }}>
                <h4>TCA Claim</h4>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input className='checkbox-styled' style={{ height: '15px', width: '15px' }} type='checkbox' checked={checkSendClaim} onChange={handleSendClaim} />
                        <span>Send claim to TCA</span>
                        <ConfirmationModal
                            isOpen={isOpen1}
                            message="Are you sure to send the claim?"
                            onConfirm={handleConfirm1}
                            onCancel={handleCancel}
                        />
                        {/* {checkSendClaim &&
                            <div>
                                <button type='submit' className="curPo confirm">Confirm (TC)</button>
                                <button type='reset' className="curPo">Cancel</button>
                            </div>} */}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input className='checkbox-styled' style={{ height: '15px', width: '15px' }} type='checkbox' checked={checkDownloadClaim} onChange={handleDownloadClaim} />
                        <span>Download claim file</span>
                        <ConfirmationModal
                            isOpen={isOpen2}
                            message="Are you sure download the file?"
                            onConfirm={handleConfirm2}
                            onCancel={handleCancel}
                        />
                        {/* {checkDownloadClaim &&
                            <div>
                                <span style={{ marginLeft: "25px" }}>Claim submitted to TCA manually</span>
                                <button type='submit' className="curPo confirm">Confirm (TC)</button>
                                <button type='reset' className="curPo">Cancel</button>
                            </div>} */}
                    </div>
                </div>
            </div>
            <div>
                <h4>A1 claim URN</h4>
            </div>

            <div>
                <hr></hr>
                <h5>TCA claim detail</h5>
                <p>Request Type:</p>
                <p>Service Scenario Applied:</p>
                <p>Consumer Service Solution:</p>
            </div>
            <div style={{ display: 'flex', gap: '10%' }}>
                <p>Spare Parts</p>
                <table className='after-repair-table'>
                    <thead>
                        <tr>
                            <th>Parts PO No</th>
                            <th>PCM Part No</th>
                            <th>Cond Code</th>
                            <th>Symptom Code</th>
                            <th>Repair Code</th>
                            <th>Sec Code</th>
                            <th>Defect Code</th>
                            <th>Loc Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style={{ display: 'flex', gap: '10%' }}>
                <p>Cost:</p>
                <table className='after-repair-table'>
                    <thead>
                        <tr>
                            <th>Total Cost</th>
                            <th>Labor Cost</th>
                            <th>Transport</th>
                            <th>Handling Cost</th>
                            <th>Total Spare</th>
                            <th>Other Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TcaClaim
