import React, { useEffect, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Modal from 'react-modal';
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

Modal.setAppElement('#root');

const ImageModal = ({ isOpen, onRequestClose, fileContent, fileType }) => {
  const [zoom, setZoom] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (!isOpen) {
      setZoom(1);
      setPosition({ x: 0, y: 0 });
    }
  }, [isOpen]);

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3));  // Max zoom level set to 3
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1));  // Min zoom level set to 1
    if (zoom <= 1.1) {
      setPosition({ x: 0, y: 0 });
    }
  };

  const handleReset = () => {
    setZoom(1);
    setPosition({ x: 0, y: 0 });
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPos({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const newPosX = e.clientX - startPos.x;
      const newPosY = e.clientY - startPos.y;

      const image = e.target;
      const imageRect = image.getBoundingClientRect();
      const container = image.parentElement;
      const containerRect = container.getBoundingClientRect();

      const maxX = Math.max(0, (imageRect.width - containerRect.width) / 2 / zoom);
      const maxY = Math.max(0, (imageRect.height - containerRect.height) / 2 / zoom);

      setPosition({
        x: Math.max(-maxX, Math.min(maxX, newPosX)),
        y: Math.max(-maxY, Math.min(maxY, newPosY)),
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const renderContent = () => {
    if (["jpg", "jpeg", "png", "gif"].includes(fileType)) {
      return (
        <img
          src={`data:image/${fileType};base64,${fileContent}`}
          alt="Attachment"
          className="zoom-image"
          style={{
            transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
            cursor: dragging ? 'grabbing' : 'grab',
          }}
        />
      );
    } else if (fileType === 'pdf') {
      return (
        <div className="zoom-image" style={{ transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`, cursor: dragging ? 'grabbing' : 'grab' }}>
          {/* <object data={`data:application/pdf;base64,${fileContent}`} type="application/pdf" width="100%" height="100%">
            <p>Alternative text - include a link <a href={`data:application/pdf;base64,${fileContent}`}>to the PDF!</a></p>
          </object> */}
          <Document file={`data:application/pdf;base64,${fileContent}`} onLoadSuccess={onDocumentLoadSuccess} >
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image Modal"
      className="modal"
      overlayClassName="overlay"
    >
      <div className="close-controls"><button onClick={onRequestClose} className="close-button">X</button></div>
      <div className="modal-content">
        <div
          className="image-container"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          style={{ cursor: dragging ? 'grabbing' : 'grab' }}
        >
          {renderContent()}
        </div>

        {["jpg", "jpeg", "png", "gif"].includes(fileType) && <div className="zoom-controls">
          <button onClick={handleZoomIn} className="zoom-button">+</button>
          <button onClick={handleZoomOut} className="zoom-button">-</button>
          <button onClick={handleReset} className="zoom-button">1:1</button>
        </div>}

        {fileType === 'pdf' && <div className="zoom-controls" style={{ display: "flex", alignItems: "center" }}>
          <button onClick={handleZoomIn} className="zoom-button">+</button>
          <button onClick={handleZoomOut} className="zoom-button">-</button>
          <button onClick={handleReset} className="zoom-button">1:1</button>
          <button className="zoom-button" disabled={pageNumber <= 1} onClick={() => setPageNumber(pageNumber - 1)}>
            <NavigateBeforeIcon />
          </button>
          <span> Page {pageNumber} of {numPages} </span>
          <button className="zoom-button" disabled={pageNumber >= numPages} onClick={() => setPageNumber(pageNumber + 1)}>
            <NavigateNextIcon />
          </button>
        </div>}
      </div>
    </Modal>
  );
};

export default ImageModal;
