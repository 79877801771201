import React, { useState } from "react";
import WoDashboard from "./components/WoDashboard";
import DetailedInfo from "./components/DetailedInfo";
import TabContext from "./TabContext";

// Tab component
const Tab = ({ id, onClose, isActive, onClick }) => (
  <div className={`tab ${isActive ? "active" : ""}`}>
    <span className={`${id === "dashboard" ? "uline" : ""} curPo`} onClick={onClick}>{`${id === "dashboard" ? "WO Overview" : id}`}</span>
    {id !== "dashboard" && (
      <button className={`${isActive ? "active-close-button" : "inactive-close-button"}`} onClick={() => onClose(id)} > x </button>
    )}
  </div>
);

function App() {
  const [tabs, setTabs] = useState([{ id: "dashboard" }]);
  const [activeTab, setActiveTab] = useState("dashboard");

  // Function to add new tab
  const openTab = (link) => {
    if (!tabs.find(tab => tab.id === link)) {
      const id = link; // Generate unique id
      setTabs([...tabs, { id }]);
      setActiveTab(id);
    }
    setActiveTab(link);
  };

  // Function to close tab
  const closeTab = (id) => {
    const updatedTabs = tabs.filter((tab) => tab.id !== id);
    setTabs(updatedTabs);
    setActiveTab(activeTab === id ? "dashboard" : activeTab); // Switch to dashboard if closed tab was active
  };

  // Function to render content based on active tab
  const renderContent = (tab) => {
    switch (tab.id) {
      case "dashboard":
        return <TabContext.Provider value={{ openTab }}><WoDashboard /></TabContext.Provider>;
      default:
        return <DetailedInfo WoNum={tab.id} />;
    }
  };

  return (
    <div className="main-container">
      <div className="tabs-container">
        {/* Render tabs for opened tabs */}
        <div className="tabs">
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              id={tab.id}
              onClose={closeTab}
              isActive={activeTab === tab.id}
              onClick={() => {
                setActiveTab(tab.id);
              }} // Set active tab when clicked
            />
          ))}
        </div>

        {/* Render tab content for opened tabs */}
        <div className="tab-content">
          {tabs.map((tab) => {
            return (
              <div
                key={tab.id}
                style={{ display: activeTab === tab.id ? "block" : "none" }}
              >
                {renderContent(tab)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default App;
