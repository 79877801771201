import React, { useState } from "react";
import DatePicker from "react-datepicker";
import ConfirmationModal from "./ConfirmationModal";
import axios from "axios";
// import "react-datepicker/dist/react-datepicker.css";

function HomeVisit({ detailedData }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const currentDate = new Date();
  const [email, setEmail] = useState(detailedData.Email)
  const [mobile, setMobile] = useState(detailedData.Mobil)
  const [informByEmail, setinformByEmail] = useState(false)
  const [informByMobile, setinformByMobile] = useState(false)

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInformByEmail = () => {
    setinformByEmail(!informByEmail)
  }

  const handleInformByMobile = () => {
    setinformByMobile(!informByMobile)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  }

  const handleAlert = () => {
    if (email !== "") {
      setIsOpen1(true)
    } else {
      alert("Enter the Email!")
    }
  }

  const handleConfirm1 = async (mailId) => {
    setIsOpen1(false);
    const mailData = {
      // to: mailId,
      to: "rohanprasad1348@gmail.com",
      subject: "testing NodeMailer",
      text: "test",
      html: "<b>Test</b>"
    }
    try {
      await axios.post('https://wprepairacc.tpvai.com/api/sendMail', { mailData });
      // console.log(response.data);
      window.alert("Mail sent.")
    } catch (error) {
      console.error("error sending mail", error);
    }
  };

  const handleCancel = (e) => {
    setIsOpen1(false);
  };

  return (
    // <div>
    //   <div className="address">
    //     <h4>Home Visit address:</h4>
    //     <div>
    //       {detailedData.pickup_address.split(",").map((val, i) => {
    //         return <p key={i}>{val}</p>;
    //       })}
    //     </div>
    //     <div>
    //       <p>Tel: {detailedData.pickup_phone}</p>
    //       <p>Email: {detailedData.pickup_email}</p>
    //     </div>
    //     <div>
    //       {isOpen && (
    //         <div className="dropdown-calendar-container">
    //           <DatePicker
    //             selected={selectedDate}
    //             onChange={handleDateChange}
    //             inline
    //             required
    //             maxDate={currentDate}
    //             onClickOutside={() => setIsOpen(false)}
    //           />
    //         </div>
    //       )}
    //     </div>
    //   </div>

    //   <div className="plan-request-date-container">
    //     <h4>Home Visit:</h4>
    //     <div>
    //       <div style={{ display: 'flex', alignItems: "baseline", gap: '20px' }}>
    //         <p>Planned Date & Time:</p>
    //         <select></select>
    //         <button className="curPo confirm">Confirmed (181)</button>
    //         <button className="curPo">Cancel</button>
    //         <button
    //           className="curPo confirm selected-date"
    //           onClick={toggleDropdown}
    //         >
    //           Change planned date & time
    //         </button>
    //       </div>
    //       <div style={{display: "flex", gap: '20px'}}>
    //         <span>Inform Customer for home visit details: </span>
    //         <div>
    //           <div>
    //             <input style={{ height: '15px', width: '15px' }} type='checkbox' />
    //             <span style={{ marginRight: "20px" }}>by email</span>
    //             <span style={{ marginRight: "20px" }}>Email:</span>
    //             <input type="email" id="email" value={detailedData.pickup_email}/>
    //           </div>
    //           <div>
    //             <input style={{ height: '15px', width: '15px' }} type='checkbox' />
    //             <span style={{ marginRight: "20px" }}>by SMS</span>
    //             <span style={{ marginRight: "20px" }}>Mobile:</span>
    //             <input type="tel" id="myInput" value={detailedData.pickup_phone}/>
    //           </div>
    //           <button style={{ margin: '20px' }} className="curPo confirm">Confirmed ( )</button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div style={{ marginLeft: "2%" }}>
      <div className="address">
        <h4>Home Visit address:</h4>
        <div>
          {/* {"1234 Elm Street, City, State, ZIP".split(",").map((val, i) => { */}
          {`${detailedData.Firstline}, ${detailedData.Street}, ${detailedData.Postcode} ${detailedData.City}, ${detailedData.CountryId}`.split(",").map((val, i) => {
            return <p key={i}>{val}</p>;
          })}
        </div>
        <div>
          <p>Tel: {detailedData.Mobil}</p>
          <p>Email: {detailedData.Email}</p>
        </div>
        <div>
          {isOpen && (
            <div style={{ marginLeft: "2%" }} className="dropdown-calendar-container">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                required
                maxDate={currentDate}
                onClickOutside={() => setIsOpen(false)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="plan-request-date-container">
        <h4>Home Visit:</h4>
        <div>
          <div style={{ display: 'flex', alignItems: "baseline", gap: '20px' }}>
            <p>Planned Date & Time:</p>
            <select className="select-styled">
              <option value="">Select Date</option>
            </select>
            <button className="curPo confirm">Confirmed (181)</button>
            <button className="curPo cancel">Cancel</button>
            <button
              className="curPo confirm selected-date"
              onClick={toggleDropdown}
            >
              Change planned date & time
            </button>
          </div>
          <div style={{ display: "flex", gap: '20px' }}>
            <span>Inform Customer for home visit details: </span>
            <div>
              <div style={{ display: "grid", gridTemplateColumns: "30px 70px 70px auto", marginBottom: "5px" }}>
                <input className='checkbox-styled' type='checkbox' onChange={handleInformByEmail} />
                <span>by email</span>
                <span>Email:</span>
                <input style={{ boxSizing: "border-box", border: "solid 1px black" }} type="email" id="email" onChange={handleEmailChange} value={email} />
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "30px 70px 70px auto" }}>
                <input className='checkbox-styled' type='checkbox' onChange={handleInformByMobile} />
                <span>by SMS</span>
                <span>Mobil:</span>
                <input style={{ boxSizing: "border-box", border: "solid 1px black" }} type="tel" id="myInput" onChange={handleMobileChange} value={mobile} />
              </div>
              <button style={{ margin: '20px' }} className="curPo confirm" type="button" onClick={handleAlert}>Confirmed ( )</button>
              <ConfirmationModal
                isOpen={isOpen1}
                message="Send Mail ?"
                onConfirm={() => handleConfirm1(email)}
                onCancel={handleCancel}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeVisit;
