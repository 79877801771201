
export function Footer({position}) {
    console.log(position , "POSITIONN")
    return (
        <div style={{backgroundColor:'#0079c2' , position:position,
        left:0,
        bottom:0,
        right:0 ,
        textAlign: 'center',
        marginTop: '20px',
        width: '100%',
        padding: '15px',
        color: '#fff',
        fontSize:'20px'}}>&copy; 2024 TP Vision</div>
    )
}