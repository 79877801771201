import React, { useState ,useEffect} from "react";
import ConfirmationModal from './ConfirmationModal';
import axios from "axios";

function AssignTechnician({ detailedData }) {
  console.log(detailedData,"getttt")
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [toggleReassign1, setToggleReassign1] = useState(false);
  const [toggleReassign2, setToggleReassign2] = useState(false);
  const [toggleReassign3, setToggleReassign3] = useState(false);

  const handleAlert1 = () => {
    setIsOpen1(true)
  }

  // const handleConfirm1 = () => {
  //   setIsOpen1(false)
  //   setToggleReassign1(false)
  // }
  const handleConfirm1 = () => {
    // Gather the necessary data to save
    const requestData = {
      technician: selectedTechnician,  // Assuming you have selectedTechnician state
      workshop: detailedData.WorkshopName,                // Assuming you have comment state
      // asc: asc ,
      action: "Assign Technisian",
      assigny :localStorage.getItem('username'),
      wonumber: detailedData.OrderName,
      sn: detailedData.SerialNumber
    };
    console.log(requestData,"asssign")
    // Make an HTTP request to save the data (replace with your actual endpoint)
    axios.post('https://wprepairacc.tpvai.com/api/woassignment', requestData)
      .then(response => {
        // Handle success, such as showing a success message or updating UI
        console.log('Data saved successfully:', response.data);     
        // Close modal and toggle off reassign after success
        setIsOpen1(false);
        setToggleReassign1(false);
        // Refresh assignment history after successful save
        fetchAssignmentHistory();
      })
      .catch(error => {
        // Handle error, such as showing an error message
        console.error('Error saving data:', error);
      });
  };
  
  const [assignmentHistory, setAssignmentHistory] = useState([]);
  const fetchAssignmentHistory = () => {
    // Fetch WO assignment history from the backend
    axios.post('https://wprepairacc.tpvai.com/api/woassignment/history',{wo: detailedData.OrderName})
    .then(response => {
      let historyData = response.data;

      // Check the usertype in localStorage
      const userType = localStorage.getItem('usertype');
      // If the usertype is "TPV technician", filter the data based on action
      if (userType === 'TPV technician' || userType === 'Manager') {
        historyData = historyData.filter(item => item.action === 'Assign Technisian');
      }

      setAssignmentHistory(historyData);
      console.log('Assignment history fetched successfully:', response.data);
    })
    .catch(error => {
      console.error('Error fetching assignment history:', error);
    });
  };
  
  useEffect(() => {
    // Fetch history when component mounts
    fetchAssignmentHistory();
  }, []);

  const handleCancel1 = () => {
    setIsOpen1(false)
  }

  const handleReassign1 = () => {
    setToggleReassign1(true)
  }

  const handleAlert2 = () => {
    setIsOpen2(true)
  }

  const handleConfirm2 = () => {
    // Gather the necessary data to save
    const requestData = {
      // technician: selectedASC,  // Assuming you have selectedTechnician state
      workshop: selectedWorkshop,                // Assuming you have comment state
      // asc: selectedASC,
      action: "Assign Workshop",
      assigny :localStorage.getItem('username'),
      wonumber: detailedData.OrderName,
      sn: detailedData.SerialNumber
    };
    console.log(requestData,"asssign")
    axios.post('https://wprepairacc.tpvai.com/api/woassignment', requestData)
      .then(response => {
        console.log('Data saved successfully:', response.data);     
        setIsOpen2(false);
        setToggleReassign2(false);
        fetchAssignmentHistory();
      })
      .catch(error => {
        console.error('Error saving data:', error);
      });
  };
  const handleCancel2 = () => {
    setIsOpen2(false)
  }

  const handleReassign2 = () => {
    setToggleReassign2(true)
  }
  

  const handleAlert3 = () => {
    setIsOpen3(true)
  }

  const handleConfirm3 = () => {
    // Gather the necessary data to save
    const requestData = {
      // technician: selectedASC,  // Assuming you have selectedTechnician state
      workshop: detailedData.WorkshopName,                // Assuming you have comment state
      asc: selectedASC,
      action: "Assign ASC",
      assigny :localStorage.getItem('username'),
      wonumber: detailedData.OrderName,
      sn: detailedData.SerialNumber
    };
    console.log(requestData,"asssign")
    axios.post('https://wprepairacc.tpvai.com/api/woassignment', requestData)
      .then(response => {
        console.log('Data saved successfully:', response.data);     
        setIsOpen3(false);
        setToggleReassign3(false);
        fetchAssignmentHistory();
      })
      .catch(error => {
        console.error('Error saving data:', error);
      });
  };

  const handleCancel3 = () => {
    setIsOpen3(false)
  }

  const handleReassign3 = () => {
    setToggleReassign3(true)
  }
  const technicians = ['Technician 1', 'Technician 2', 'Technician 3', 'Technician 4'];
  const ascs = ['ASC 1', 'ASC 2', 'ASC 3', 'ASC 4'];
  const countryascs = ['Country-ASC 1', 'Country-ASC 2', 'Country-ASC 3', 'Country-ASC 4'];
  const workshops = ['Workshop 1', 'Workshop 2', 'Workshop 3', 'Workshop 4'];

  const [selectedTechnician, setSelectedTechnician] = useState('');
  const handleTechnicianChange = (event) => {
    setSelectedTechnician(event.target.value); // Capture selected technician
  };

  const [selectedASC, setSelectedASC] = useState('');
  const handleASCChange = (event) => {
    setSelectedASC(event.target.value); // Capture selected technician
  };

  const [selectedWorkshop, setSelectedWorkshop] = useState('');
  const handleWorkshopChange = (event) => {
    setSelectedWorkshop(event.target.value); // Capture selected technician
  };
  
  const [userType, setUserType] = useState('');
  useEffect(() => {
    // Get usertype from localStorage
    const storedUserType = localStorage.getItem('usertype');
    setUserType(storedUserType);
  }, []);

  return (
    <div className="assign-technician-container">
      <h4>Assign WO to Technician</h4>
      <form className="assign-form">
        <label>Defect TV SN:</label>
        <span className='defect-tv-sn'>{detailedData.SerialNumber}</span>
        <label>Technician:</label>
        <select className="select-styled" onChange={handleTechnicianChange}>
          <option value="" disabled selected>Select technician</option>
          {technicians.map((tech, index) => (
            <option key={index} value={tech.toLowerCase().replace(' ', '-')}>
              {tech}
            </option>
          ))}
        </select>
        <div className="confirm-cancel-buttons" style={{ gridColumn: "span 3" }}>
          <button className="confirm" type="button" onClick={handleAlert1}>Confirm (AT)</button>
          <ConfirmationModal
            isOpen={isOpen1}
            message="Are you sure you want to assign this technician?"
            onConfirm={handleConfirm1}
            onCancel={handleCancel1}
          />
          <button className="cancel" type="button">Cancel</button>
          <div className="reassign-section">
            {toggleReassign1 ?
              <div style={{ display: "flex", marginLeft: "5px" }}>
                <span>New Technician: </span>
                <select className="select-styled" onChange={handleTechnicianChange}>
                  <option value="" disabled selected>Select technician</option>
                  {technicians.map((tech, index) => (
                    <option key={index} value={tech.toLowerCase().replace(' ', '-')}>
                      {tech}
                    </option>
                  ))}
                </select>
                {userType !== 'TPV Technician' && (
                <button className="confirm" type="button" onClick={handleAlert1}>Confirm (RT)</button>)}
                <button className="cancel" type="button" onClick={() => setToggleReassign1(false)}>Cancel</button>
              </div> : <button type="button" className="reassign-button" onClick={handleReassign1}>Re-assign</button>}
          </div>
        </div>

      </form>

      <h4>Assign WO to Workshop/ASC</h4>
      {/* workshop */}
      <div>
        <div className="assign-form" style={{ display: "flex", alignItems: "center" ,marginBottom:"10px" }}>
          <label>WO handled by workshop:</label>
          <div style={{ marginLeft: '5px' }}>
            <span className='defect-tv-sn' style={{ marginLeft: "10px" }}>
              {detailedData.WorkshopName}
            </span>
          </div>
          <div className="confirm-cancel-buttons" style={{ gridColumn: "span 3", marginLeft: "20px", display: "flex", alignItems: "center" }}>
            <div className="reassign-section">
              {toggleReassign2 ? (
                <div style={{ display: "flex", marginLeft: "5px", alignItems: "center" }}>
                  <span>New Workshop: </span>
                  <select className="select-styled" style={{ marginLeft: "10px" }} onChange={handleWorkshopChange}>
                    <option value="" disabled selected>Select Workshop</option>
                    {workshops.map((asc, index) => (
                      <option key={index} value={asc.toLowerCase().replace(' ', '-')}>
                        {asc}
                      </option>
                    ))}
                  </select>
                  <span style={{ marginLeft: "10px" }}>Add Comment: </span>
                  <input className="input-styled" type="text" style={{ marginLeft: "10px" }}></input>
                  <button className="confirm" type="button" onClick={handleAlert2} style={{ marginLeft: "10px" }}>Confirm (RA)</button>
                  <ConfirmationModal
                    isOpen={isOpen2}
                    message="Are you sure you want to assign this workshop?"
                    onConfirm={handleConfirm2}
                    onCancel={handleCancel2}
                  />
                  <button className="cancel" type="button" onClick={() => setToggleReassign2(false)} style={{ marginLeft: "10px" }}>Cancel</button>
                </div>
              ) : 
                (userType !== 'TPV Technician' && (
                <button type="button" className="reassign-button" onClick={handleReassign2}>Re-assign</button>
                )
              )}
            </div>
          </div>
        </div>

     
      {/* ASC */}
      <div className="assign-form">
        <label>WO handled by ASC:</label>
        <select className="select-styled"  onChange={handleASCChange}>
          <option value="" disabled selected>Select ASC</option>
          {ascs.map((asc, index) => (
            <option key={index} value={asc.toLowerCase().replace(' ', '-')}>
              {asc}
            </option>
          ))}
        </select>
        <div className="confirm-cancel-buttons" style={{ gridColumn: "span 3" }}>
          <button className="confirm" type="button" onClick={handleAlert3}>Assign (AA)</button>
          <ConfirmationModal
            isOpen={isOpen3}
            message="Are you sure you want to assign this ASC?"
            onConfirm={handleConfirm3}
            onCancel={handleCancel3}
          />

          <div className="reassign-section">
            {toggleReassign3 ?
              <div style={{ display: "flex", marginLeft: "5px" }}>
                <span>New ASC: </span>
                <select className="select-styled">
                  <option value="" disabled selected>Select ASC</option>
                  {countryascs.map((asc, index) => (
                    <option key={index} value={asc.toLowerCase().replace(' ', '-')}>
                      {asc}
                    </option>
                  ))}
                </select>
                <span>Add Comment: </span>
                <input className="input-styled" type="text"></input>
                <button className="confirm" type="button" onClick={handleAlert3} >Confirm (RA)</button>
                <button className="cancel" type="button" onClick={() => setToggleReassign3(false)}>Cancel</button>
              </div> : (userType !== 'TPV Technician' && (
            <button type="button" className="reassign-button" onClick={handleReassign3}>Re-assign</button>
          )
        )}
          </div>
        </div>
      </div>
      </div>
      <div>
        <h4>WO Assignment History:</h4>
        <table className='assign-history-table'>
          <thead>
            <tr>
              <th>Date Time</th>
              <th>Workshop</th>
              <th>ASC</th>
              <th>Technician</th>
              <th>Action</th>
              <th>Assigned by</th>
            </tr>
          </thead>
          <tbody>
            {assignmentHistory.length > 0 ? (
              assignmentHistory.map((history, index) => (
                <tr key={index}>
                  <td>{history.createdAt}</td>
                  <td>{history.workshop}</td>
                  <td>{history.asc}</td>
                  <td>{history.technician}</td>
                  <td>{history.action}</td>
                  <td>{history.assigny}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No assignment history available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AssignTechnician;
