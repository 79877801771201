import React, { useState } from 'react'
import DashboardTable from "./DashboardTable";
import CreateOrder from "./CreateOrder";

function WoDashboard() {

  const [refreshData, setRefreshData] = useState(false);
  const refreshDashboardData = () => {
    setRefreshData(prevState => !prevState);
  };
  const usertype = localStorage.getItem('usertype');
  // console.log("Dashboard Component about to open");
  return (
    <div>
      <div>
        <DashboardTable refreshData={refreshData}/>
        {usertype !== 'Manager' && (
        <CreateOrder onOrderCreated={refreshDashboardData}/>
        )}
      </div>
    </div>
  )
}

export default WoDashboard
